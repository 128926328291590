import { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../atoms/CustomButton";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import Delete from "../../../icons/Delete";
import Edit from "../../../icons/Edit";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Search from "../../../molecules/search";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../organisms/modals/Modal";
import Form from "./AddSurvey/addSurvey";
import Pagination from "../../../molecules/Pagination";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { deleteData, getData, postData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import NoContentIcon from "../../../icons/NocontentIcon";
import moment from "moment/moment";
import SurveyIcon from "../../../icons/surveyIcon";
import ShareIcon from "../../../icons/shareIcon";
import TagInput from "../../../atoms/TagInput";
import { Tooltip } from "@mui/material";
import InfoCard from "../../../atoms/InfoCard";
import Business from "../../../icons/Business";
import AdminIcon from "../../../icons/admin";

const Survey = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "role"]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });
  const [surveyModalState, setSurveyModalState] = useState({
    isModalOpen: false,
    modalData: null,
    modalId: "",
    modalTitle: "",
  });
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [shareEmail, setShareEmail] = useState();
  const [emailError, setEmailError] = useState(null); // State to store error message
  const [shareSurveyData, setShareSurveyData] = useState();
  const [infoCardData, setInfoCardData] = useState();
  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emails.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(", ")}`);
    } else {
      setEmailError(null);
    }

    return emails.filter((email) => emailRegex.test(email));
  };

  const getDashBoardInfoCardData = async () => {
    try {
      const res = await getData({
        endpoint: "getAllDashboard",
        token: cookies.t,
      });
      setInfoCardData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getDashBoardInfoCardData();
  }, []);

  const getSurveyData = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "survey/getAllSurvey",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getSurveyData();
  }, [getSurveyData]);

  const totalItems = tableData?.[0]?.trialData;

  const handleEdit = (id) => {
    navigate(`/dashboard/survey/edit/${id}`);
  };
  const handleView = (id) => {
    navigate(`/dashboard/company/view/${id}`);
  };
  const handleTable = (id) => {
    navigate(`/dashboard/company/table/${id}`);
  };
  const handleSurveyEdit = (id) => {
    navigate(`/dashboard/survey/surveyor/${id}`, { state: "Surveyor" });
  };

  const handleDelete = (id, name) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: id,
      name: name,
    }));
  };
  const handleSurveyShare = async (id, data) => {
    try {
      const res = await getData({
        endpoint: "shareSurvey/getShareSurveyBySurveyId",
        token: cookies.t,
        params: {
          survey_id: data?._id,
        },
        data: {},
      });
      setShareSurveyData(res?.data);
      setShareEmail(res?.data?.emailDetail?.map((item) => item?.email));
    } catch (err) {
      console.log("err", err);
    }
    setSurveyModalState(() => ({
      isModalOpen: true,
      modalData: data,
      modalId: id,
      modalTitle: "Add / modify surveyors",
    }));
  };

  const handleSurveyShareClose = () => {
    setSurveyModalState(() => ({
      isModalOpen: false,
      modalData: null,
      modalId: "",
      modalTitle: "",
    }));
    setShareEmail("");
  };

  const handleDeleteConfirm = async (id) => {
    try {
      // Add your delete logic here, for example:
      const res = await deleteData({
        endpoint: `survey/deleteSurvey`,
        params: {
          id: id,
        },
        token: cookies.t,
      });
      if (res?.data?.status) {
        toast.success("Survey Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
      // Refresh the data after deletion
      getSurveyData();
    } catch (error) {
      console.error(error);
    } finally {
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      if (action === "viewCompany") {
        handleView(selectedRow._id, "view");
      } else if (action === "viewFinancialData") {
        handleTable(selectedRow._id);
      }
    }
    handleMenuClose();
  };

  const columns = [
    {
      field: "survey_name",
      headerName: "Survey Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.survey_name ? params?.row?.survey_name : "--"}
          </div>
        );
      },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return <div>{params?.row?.client_name}</div>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => {
        return <div>{moment(params?.row?.date).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      // sortable: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Survey" placement="bottom">
              <span
                className="me-2 pointer"
                onClick={() => handleEdit(params.row._id)}
              >
                <Edit />
              </span>
            </Tooltip>
            <Tooltip title="Surveyor" placement="bottom">
              <span
                className="me-2 pointer"
                onClick={() => handleSurveyEdit(params.row._id)}
              >
                <SurveyIcon />
              </span>
            </Tooltip>
            <Tooltip title="Share" placement="bottom">
              <span
                className="me-2 pointer"
                onClick={() => handleSurveyShare(params.row._id, params?.row)}
              >
                <ShareIcon />
              </span>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <span
                className="me-2 pointer"
                onClick={() => handleDelete(params.row._id, params.row.name)}
              >
                <Delete color="black" />
              </span>
            </Tooltip>
            {/* <span
              onClick={(event) => handleMenuOpen(event, params.row)}
              className="me-2 pointer"
            >
              <GridMoreVertIcon />
            </span>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("viewCompany")}>
                Company Details
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("viewFinancialData")}
              >
                Financial Data
              </MenuItem>
            </Menu> */}
          </>
        );
      },
    },
  ];

  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };
  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleShareSurvey = async (shareEmail) => {
    const dataToSend = {
      survey_name: surveyModalState?.modalData?.survey_name,
      survey_id: surveyModalState?.modalData?._id,
      emailDetail: shareEmail?.map((item) => {
        return {
          email: item,
          otp: null,
        };
      }),
    };
    try {
      const res = await postData({
        endpoint: "shareSurvey/createShareSurvey",
        token: cookies.t,
        params: {},
        data: dataToSend,
      });
      if (res) {
        toast.success("Survey shared Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
      setSurveyModalState(() => ({
        isModalOpen: false,
        modalData: null,
        modalTitle: "",
        modalId: "",
      }));
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <>
      <div className="header-infoCardWrap">
        <PageHeader title="Survey" type="small" />
        <div className="infoCardWrap">
          <InfoCard
            color="#B1E5FC"
            title="Total Survey"
            data={infoCardData ? infoCardData?.surveyData : 0}
            svgIcon={<Business />}
            isSvg
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#B1E5FC"
            title="Total User"
            data={infoCardData ? infoCardData?.userData : 0}
            svgIcon={<AdminIcon />}
            isSvg
            // data={data?.total_company || 0}
          />
        </div>
      </div>
      <PageCard>
        <div className="d-flex space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          {atob(cookies.role) === "Admin" && (
            <div className="d-flex gap-3">
              <CustomButton
                type="btn-primary"
                text="Add Survey"
                handleClick={() => navigate("add")}
              />
            </div>
          )}
        </div>

        {tableData?.[0]?.trialData && tableData?.[0]?.trialData?.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              rows={tableData?.[0]?.trialData || []}
              columns={columns}
              tHeight="50vh"
            />

            {tableData?.[0]?.pageDetails?.count > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={tableData?.[0]?.pageDetails?.count}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
      </PageCard>

      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: "visible",
            cssText: "overflow: visible !important",
          }}
          width="600px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === "Edit Location" ? modalState.modalData : null
            }
            // refetchData={getUserData}
          />
        </Modal>
      )}
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          // height="300px"
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Company"
          onClick={() => handleDeleteConfirm(modalState.modalId)}
        />
      )}
      {surveyModalState?.isModalOpen && (
        <Modal
          isActive={surveyModalState.isModalOpen}
          title={surveyModalState?.modalTitle}
          onClose={handleSurveyShareClose}
          width="600px"
        >
          <>
            <TagInput
              type="text"
              name="Email"
              customholder="Enter Email"
              InputHeight="small"
              placeholder="Enter Email"
              onChange={(tags) => {
                const validEmails = validateEmails(tags);
                setShareEmail(validEmails);
              }}
              selectedTags={shareEmail}
              error={emailError}
            />
            <div className="ShareButtonWrap">
              <CustomButton
                text="Share"
                iconRequired={false}
                type="btn-primary"
                size="btn-lg"
                handleClick={() => {
                  handleShareSurvey(shareEmail);
                }}
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
export default Survey;
