import moment from 'moment';
export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatDateToISO = (date) => {
    // Use the Date object to format the date to ISO string with milliseconds
    const formattedDate = new Date(date).toISOString();
    return formattedDate;
};

export const formatToDefault = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
}

export const formatToDateTime = (date) => {
    const formattedDate = moment(date).format("D MMM YYYY | h:mm A");
    return formattedDate
}

export const formatTimeStamp = (date) => {
    const formattedTimestamp = moment(date).format("YYYY-MM-DDTHH:mm");
    return formattedTimestamp
}
