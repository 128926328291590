import { Navigate } from "react-router-dom";
import { useAuthenticated } from "../hooks/useAuthenticated";

const ShareGuard = ({ children }) => {
  const auth = useAuthenticated();

  if (!auth) {
    // return <Navigate to="/dashboard/company" />;
    return <>{children}</>;
  }
  return <>{children}</>;
};
export default ShareGuard;
