import { useEffect, useState } from "react";
import Search from "../search";
import "./sideSearchBar.scss";

const SideSearchBar = ({
  searchQuery,
  setSearchQuery,
  NavigationData,
  SetNavigationClicked,
}) => {
  const [filterSearchData, setFilterSearchData] = useState(NavigationData);

  useEffect(() => {
    if (searchQuery?.length) {
      // Filter data based on search query
      const filteredOptions = NavigationData?.filter((option) =>
        option?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
      );
      setFilterSearchData(filteredOptions);
    } else {
      setFilterSearchData(NavigationData);
    }
  }, [searchQuery]);

  return (
    <div className="sidebarSearch">
      <div className="navigation">Navigation</div>
      <div className="searchWrap">
        <Search
          placeholder="Search by Header"
          onSearchEmit={(option) => {
            setSearchQuery(option);
          }}
          value={searchQuery}
        />
      </div>
      <div className="navigationWrap">
        {filterSearchData?.map((item) => {
          return (
            <div>
              <label
                className="navigationName"
                onClick={(e) => {
                  SetNavigationClicked(e.target.innerText);
                }}
              >
                {item}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SideSearchBar;
