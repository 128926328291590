export const statusData = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const userRoles = [
  { label: "Admin ", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Viewer", value: "Viewer" },
  { label: "Surveyor", value: "Surveyor" },
];

export const businessTypes = [
  { value: "Private Limited", label: "Private Limited" },
  { value: "Public Limited", label: "Public Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Individual", label: "Individual" },
  { value: "Partnership", label: "Partnership" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { value: "Corporation", label: "Corporation" },
  {
    value: "Non-Government Organization",
    label: "Non-Government Organization",
  },
];

export const formType = [
  { value: "MultipleChoice", label: "Multiple choice" },
  { value: "Checkboxes", label: "Checkboxes" },
  { value: "DropDown", label: "Drop-Down" },
];

export const auditStatusOptions = [
  { value: "audited", label: "Audited" },
  { value: "unaudited", label: "Unaudited" },
];

export const periodTypeOptions = [
  { value: "yearly", label: "Yearly" },
  { value: "halfYearly", label: "Half-Yearly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "monthly", label: "Monthly" },
];

export const sheetData = [
  { value: "balance_sheet", label: "Balance Sheet" },
  { value: "pl", label: "P&L" },
  { value: "other", label: "Other" },
];

export const NavigationData = [
  "Verification Page",
  "Table of Content",
  "Disclaimer",
  "Document Revision Record",
  "Amendment Record",
  "Summary",
  "Overview",
  "A-1: Introduction",
  "A-1.1: Instruction",
  "A-1.2: Scope of Work",
  "A-1.3: Referenced and Associated Publications",
  "A-1.4: Key Personnel in Attendance at the Trials",
  "A-1.5: Order of Trials",
  "A-1.6: Requirements during Testing",
  "A-2: Vessel Particulars",
  "A-2.1: General",
  "A-2.2: Main Engines & Auxiliary Diesel Engine Generators",
  "A-2.3: Thrusters and Propulsion",
  "A-2.4: Electric Distribution",
  "A-2.5: VESSEL LAYOUT / REDUNDANCY CONCEPT",
  "A-2.6: DP System",
  "A-2.7: MACHINERY CONFIGURATION DURING TRIALS",
  "A-2.8: WORST CASE FAILURE DESIGN INTENT",
  "A-3: Trials Summary",
  "A-3.1: Documentation",
  "A-3.2: Machinery Maintenance",
  "A-3.3: DP System Maintenance",
  "A-3.4: Incidents",
  "A-3.5: Power Generation",
  "A-3.6: Power Distribution",
  "A-3.7: Power Management",
  "A-3.8: Control Loops",
  "A-3.9: Environmental and Heading Sensors",
  "A-3.10: Position References",
  "A-3.11: DP Control",
  "A-3.12: Personnel",
  "A-3.13: Trials Conclusions",
  "Repeat Findings (Still Open) from Previous DP Trials",
  "A-5: Findings",
  "Section B: Trials Records",
  "B-1: Document and Maintenance Audit",
  "B-1.1: Machinery maintenance",
  "B-1.2: Switchboard Maintenance",
  "B-1.3: DP System Maintenance",
  "DP Computers",
  "B-1.4: Battery Maintenance",
  "B-1.5: Checklists and DP Related Documentation",
  "B-1.6: HARDWARE MODIFICATIONS",
  "B-1.7: CAPABILITY PLOTS",
  "B-1.8: DP INCIDENTS",
  "B-1.9: DP Training of Personnel",
  "B-1.10: PRE-TRIAL CHECKS",
  "B-2: DP TRIAL TEST SCHEDULE",
  "Software Test",
];

export const testData = {
  "SOFTWARE AUDIT": [
    {
      name: "SOFTWARE AUDIT",
      equipment: "DP  SYSTEM",
      testNo: "Test No.",
      objective:
        "To record the software version installed in all DP related systems at the time of trials.",
      fmeaRef: "N/A",
      method: `Record the software version from the following systems:
1.	DP Control System.
2.	IJS
3.	DGPS 1
4.	DGPS 2
5.	SpotTrack
`,
      resultsExpected: "1.	Software versions as per the latest installations. ",
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "FUEL OIL SYSTEM": [
    {
      name: "FUEL OIL SYSTEM",
      equipment: "AUX SYSTEM",
      testNo: "Test No.",
      objective: "To test FO service tank level alarms",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.3",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Simulate service tank low level Port.
2.	Simulate service tank low level Starboard
`,
      resultsExpected: `Alarms (AMS):
1.	Low level alarm initiated on AMS.
2.	Low level alarm initiated on AMS.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "COOLING WATER SYSTEM": [
    {
      name: "COOLING WATER SYSTEM",
      equipment: "AUX SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of FW cooling system",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.3",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Simulate  expansion tank low level alarm on Port ME
2.	Simulate expansion tank low level alarm on Stbd ME
3.	Simulate failure of Sea water cooling  pump (Port & Stbd) failure one by one. Restore after failure. 
4.	Simulate failure of HTFW pump on each ME
5.	Simulate failure of LTFW pump on each ME
6.	Simulate Faliure of the Aux. Cooling Sea water Pump`,
      resultsExpected: `1.	Alarm on AMS, no effect on Main Engine 
2.	Alarm on AMS, no effect on Main Engine 
3.	Alarm on AMS. The standby pump has to be started and connected manually
4.	Alarm on AMS, Loss of one Main Engine
5.	Alarm on AMS, Loss of one Main Engine
6.	Loss of Aux. Cooling sea water pump.  Operator change over to other pump. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "LUBE OIL SYSTEM": [
    {
      name: "LUBE OIL SYSTEM",
      equipment: "AUX SYSTEM",
      testNo: "Test No.",
      objective:
        "To test lube oil low level alarms and demonstrate the failure effects of ME LO system",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.3",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Simulate low lube Oil pressure on ME1 (SG 1 Bus).
2.	Simulate low lube Oil pressure on ME2 (SG 2 Bus ).
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "COMPRESSED AIR SYSTEM": [
    {
      name: "COMPRESSED AIR SYSTEM",
      equipment: "AUX SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of compressed air system",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.3",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Simulate low Pressure of air bottle 
Isolate air bottles.
2.	Drain starting air lines for Both main engine one by one and restore.`,
      resultsExpected: `1. Stby air compressor will start
2. Alarm on AMS. No effect on running engine 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "ENGINES CONTROL SUPPLY": [
    {
      name: "ENGINES CONTROL SUPPLY",
      equipment: "POWER GENERATION",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of 24V DC/220V AC power supply to main engine Control and Monitoring.",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Fail 24V DC Supply  No 1 from DC 31 to Main Engine 1 (Port). Restore.
2.	Fail 24V DC Supply  No 1 from DC 41 to Main Engine 2 (Stbd). Restore.
3.	Fail 24V DC Supply  No 2 from L7 220V  to Main Engine 1 (Port). Restore.
4.	Fail 24V DC Supply  No 2 from L8 220V  to Main Engine 2 (Stbd). Restore.`,
      resultsExpected: `1.	Alarm. No effect on DP. Redundant supply available 
2.	Alarm. No effect on DP. Redundant supply available
3.	Alarm. No effect on DP. Redundant supply available
4.	Alarm. No effect on DP. Redundant supply available
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "FAILURE OF PORT MAIN ENGINE": [
    {
      name: "FAILURE OF PORT MAIN ENGINE",
      equipment: "POWER GENERATION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effect of Port ME",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP, both main engines online, all thrusters & DG online and a minimum of 2 reference systems online.

1. Stop ME (P). Observe results and restore
`,
      resultsExpected: `1.	Alarms on AMS. ME (P) stop. Loss of SG1, 440Vac SG 1bus.
2.	Loss of Bow Tunnel Thruster 1, Stern Tunnel Thruster and Port CPP
3.	Stern Thruster main power supply auto switch over to 440Vac SG 2 Bus.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "FAILURE OF STBD MAIN ENGINE": [
    {
      name: "FAILURE OF STBD MAIN ENGINE",
      equipment: "POWER GENERATION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effect of Stbd ME",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP, both main engines online, all thrusters & DG online and a minimum of 2 reference systems online.

1. Stop ME (S). Observe results and restore

`,
      resultsExpected: `1. Alarms on AMS. ME (S) stop. Loss of SG 2, SG 2 Bus. Loss of Bow Tunnel Thruster 2 and Starboard CPP

Satisfactory station-keeping with remaining thrusters (within environmental limits)
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "MAIN ENGINES FULL LOAD TEST": [
    {
      name: "MAIN ENGINES FULL LOAD TEST",
      equipment: "POWER GENERATION",
      testNo: "Test No.",
      objective:
        "To demonstrate main engines are capable of running at rated power",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With all main engines, online, all thrusters available

1. Using manual controls raise the load on each engine in turn to 100% or maximum available and hold for 15 minutes. Note the HTFW temperature before and after.
`,
      resultsExpected: `1. Satisfactory operation of all main engines (No trips)`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "690VAC Main SWBD Bus HA": [
    {
      name: "690VAC Main SWBD Bus HA",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 690VAC MSB Bus HA",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. 

1. Fail power supply from DG1 or DG2 to 690V MSB Bus HA, observe effect and restore.
`,
      resultsExpected: `1. Loss of 690VAC MSB Bus HA leading to loss of: 
- Port VSP 
- Bow Thruster No.1 
- Bow Thruster No.2
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "690VAC Main SWBD Bus HB": [
    {
      name: "690VAC Main SWBD Bus HB",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 690VAC MSB Bus HB",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. 

1. Fail power supply from DG3 or DG4 to 690V MSB Bus HB, observe effect and restore.
`,
      resultsExpected: `1. Loss of 690VAC MSB Bus HB leading to loss of: 
- Stbd VSP 
- Stern Thruster No.1 
- Stern Thruster No.2
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "440VAC Main Bus 1  FAILURE": [
    {
      name: "440VAC Main Bus 1  FAILURE",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 440Vac Main Bus 1",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. 

1. Open DG 1 breaker to Main Bus 1
`,
      resultsExpected: `1.	Blackout of 440V AC Bus bar 1 results in loss of following DP related consumers :
•	No. 1. SGR Motor controller (Port)
•	St. by  L.O. Pump starter for Main Prop(P)
•	St. by  L.O. Pump ME(Port)
•	Pre Lo Pump ME (Port)
•	SW cooling Pump ME(Port)
•	SW cooling STBY Pump ME (P&S) 
•	Lube oil Purifier
•	Power Distribution Board -P1
•	Power Distribution Board -P4
•	Power Distribution Board -P6
•	AHT Winch Servo pump No.1
•	Transformer (T1)-80KVA
•	Steering Gear No. 1 (Port)
•	Emergency Switchboard Connection

Loss of  cooling for ME port,Loss of ME port,BT1,Main propellor (P),S/T supplied from Stb side.  Vessel maintains position with remaining thrusters.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "440VAC Main Bus 2  FAILURE": [
    {
      name: "440VAC Main Bus 2  FAILURE",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 440Vac Main Bus 2",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. 

1. Open DG 2 breaker to Main Bus 2.
`,
      resultsExpected: `1.	Blackout of 440Vac Main Bus 2 results in loss of:
•	No. 3. SGR Motor controller (Stbd)
•	St. by  L.O. Pump starter for Main Prop(Stbd)
•	St. by  L.O. Pump ME(Stbd)
•	Pre Lo Pump ME (Stbd)
•	SW cooling Pump ME(Stbd) 
•	Power Distribution Board -P2
•	Power Distribution Board -P3
•	Power Distribution Board -P5
•	Transformer (T2)-80KVA
•	Shore Connection
•	AHT Winch Servo pump No.2

  Loss of Stbd Engine cooling, Operator to line up valves and start stby SW cooling pump.Vessel maintains position with all thrusters.Loss of Redundancy
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "440VAC SG 1 Bus  FAILURE": [
    {
      name: "440VAC SG 1 Bus  FAILURE",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 440V AC SG 1 Bus",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. Simulate failure of SG1
`,
      resultsExpected: `1. Loss of BT1 and ST. Not ready alarms on DP system. ST changes over to SG 2 Bus.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "440VAC SG 2 Bus  FAILURE": [
    {
      name: "440VAC SG 2 Bus  FAILURE",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 440Vac SG 2 Bus",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.Simulate failure of SG2
`,
      resultsExpected: `1. Loss of BT2.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "440VAC EMERGENCY SWITCHBOARD": [
    {
      name: "440VAC EMERGENCY SWITCHBOARD",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of emergency switchboard",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. 

1.	Set emergency generator to manual mode.
2.	Open breaker to emergency switchboard from 440Vac Main Bus 1. Observe and restore
`,
      resultsExpected: `1.	Loss of 440 Vac ESB leads to the loss of:
•	No. 2 Steering Gear Motor Controller(Port) 
•	No. 4 Steering Gear Motor Controller (Stbd)
•	Emerg. Fire Pump
•	E/R Vent Fan Starter SE 2
•	Emg. Gen Room EXH Fan SE13
•	Water Mist System Pump Starter(P.S.P)
•	Starting Air compressor 1
•	Transformer (T3)-25KVA
•	FRC Unit
•	Loss of 220V ESB

No effect on DP position keeping. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "220VAC MSB 1 (PORT)": [
    {
      name: "220VAC MSB 1 (PORT)",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 220Vac MSB 1 (Port )",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. Simulate failure on 220Vac MSB 1 (Port)   by tripping feeder breaker of transformers feeding the switchboard
`,
      resultsExpected: `1.	Loss of 220V AC MSB leads to loss of 220 V Supply to:
•	DP UPS Supply (Inst. Rm C-Deck)
•	Joystick Main Control Cabinet
•	Gyro Compass Control Unit
•	Charger No.1 Main Battery DC30
•	Charger No.1 Main Battery DC40
•	ME no.1 Start/Stop Panel

Vessel maintains position with all thrusters
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "220VAC MSB 2 (STBD)": [
    {
      name: "220VAC MSB 2 (STBD)",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 220Vac MSB STBD",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. Simulate failure on 220Vac MSB STBD  by tripping feeder breaker of transformers feeding the switchboard
`,
      resultsExpected: `2.	Loss of 220Vac MSB STBD leads to loss of:
•	Charger No.1 Main Battery DC10
•	Charger No.1 Main Battery DC20
•	ME No.2 Start/Stop Panel
•	Various lighting distribution boards power. 

Vessel maintains position with all thrusters
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "220VAC ESB": [
    {
      name: "220VAC ESB",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of 220Vac ESB",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. Simulate failure on 220Vac ESB by switch off both supply from transformer T
`,
      resultsExpected: `1.	Loss of 220Vac ESB leads to loss of:
•	Charger No.2 Main Battery DC10
•	Charger No.2 Main Battery DC20
•	Charger Aux. Eng No.1 Battery DC 70
•	Charger Aux. Eng No.2 Battery DC 80
•	Charger No.2 Main Battery DC30
•	Charger No.2 Main Battery DC40
•	IAS UPS Supply Recep.
•	Alarm &Monitoring JB No: CL-2

No effect on position keeping
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "24VDC SYSTEM – BATTERY CHARGER for DC11,DC22,DC33 &DC44": [
    {
      name: "24VDC SYSTEM – BATTERY CHARGER for DC11,DC22,DC33 &DC44",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of 24Vdc system battery charger and to verify the performance of the battery",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1.",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Fail power supply from 220Vac to 24Vdc Supply DC11 & 21. Observe system running on batteries for at least 30 minutes.
2.	Fail power supply from 220Vac to 24Vdc Supply DC31 &41. Observe system running on batteries for at least 30 minutes.
3.	Fail all output breakers from 24V dc supply DC11. Observe results and restore
4.	Fail all output breakers from 24V dc supply DC21. Observe results and restore
5.	Fail all output breakers from 24V dc supply DC31. Observe results and restore
6.	Fail all output breakers from 24V dc supply DC41. Observe results and restore
`,
      resultsExpected: `
1.	Alarm on Bridge. Battery continues to supply power to the panel’s consumers for at least 30minutes.
2.	Alarm on Bridge. Battery continues to supply power to the panel’s consumers for at least 30minutes.
3.	The connected consumers. No effect to the Position keeping.
4.	The connected consumers. No effect to the Position keeping.
5.	The connected consumers. No effect to the Position keeping.
6.	The connected consumers. No effect to the Position keeping
`,
      actualResults: `1-2. 
3.-6 Note the equipment supply  as below
`,
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [["DC11", "DC21", "DC31", "DC41"]],
      },
    },
  ],
  "AMS FAILURE": [
    {
      name: "AMS FAILURE",
      equipment: "POWER DISTRIBUTION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of Power Supply to AMS .",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.1",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Fail 220 V AC supply to the AMS 
2.	Simulate loss of AMS . Observe result and restore. 
`,
      resultsExpected: `1.	Loss of 220 Vac to the AMS UPS. The UPS maintains the suply
2.	The AMS is lost. No effect on the DP System. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "THRUSTER & MAIN PROPULSION FULL LOAD TEST": [
    {
      name: "THRUSTER & MAIN PROPULSION FULL LOAD TEST",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective:
        "To demonstrate the ability of the thrusters to operate at rated power",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. For each thruster, test to 100% in both force directions. Thruster can be operated in pairs, to achieve opposing thrust force vectors to sustain 100% load with limited vessel movement for at least fifteen minutes
`,
      resultsExpected: `1. Satisfactory operation of thrusters and main propulsion for 15 mins`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "CPP HYDRAULIC FAILURE": [
    {
      name: "CPP HYDRAULIC FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective:
        "To demonstrate the ability of the thrusters to operate at rated power",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For each thruster in turn:
1	Simulate CPP (P) main HPP pump failure, restore
2	Simulate CPP (S) main HPP pump failure, restore
`,
      resultsExpected: `1.	Alarm on AMS. CPP (P) continue to run. Standby pump auto-start.
2.	Alarm on AMS. CPP (S) continue to run. Standby pump auto-start.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "STEERING GEARS FAILURES": [
    {
      name: "STEERING GEARS FAILURES",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of steering gears",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For each steering gear in turn:
1	Simulate steering gear (P) No.1 STG Motor Controller(p)failure, restore
2	Simulate steering gear (P) No.2 STG Motor Controller(p)failure, restore
3	Simulate steering gear (S) No.3 STG Motor Controller(s)failure, restore
4	Simulate steering gear (P) No.4 STG Motor Controller(s)failure, restore`,
      resultsExpected: `
1.	Alarm on Steering panel. Steering operational
2.	Alarm on Steering panel. Steering operational
3.	Alarm on Steering panel. Steering operational
4.	Alarm on Steering panel. Steering operational
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "BOW TUNNEL THRUSTER #1 SIGNALS FAILURE": [
    {
      name: "BOW TUNNEL THRUSTER #1 SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of Bow tunnel thruster #1 signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online Bow Tunnel Thruster 1:
1.	Fail  control power to thruster ECU from Hydraulic pp starter 1. Observe and restore.
2.	Fail  control power to thruster ECU from Hydraulic pp starter 2. Observe and restore.
3.	Fail ready signal from  TCU to DP, restore.
4.	Fail pitch order signal from DP to TCU, restore. 
5.	Fail pitch order signal from TCU to  thruster , restore. 
6.	Fail pitch feedback signal from Thruster to DP, restore. 
7.	Fail pitch feedback signal from thruster to TCU, restore.
`,
      resultsExpected: `
1.	Alarm on thruster control panel, no loss of thruster.
2.	Alarm on thruster control panel, no loss of thruster.
3.	Alarm on DP, Bow Thruster 1 not ready, pitch to zero.
4.	Alarm in DP . Thruster remains selected on DP. Pitch to zero.
5.	Alarm in DP . Thruster ot of DP. 
6.	Alarm in DP . Thruster remains selected on DP. 
7.	Alarm in DP . Thruster ot of DP. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "BOW TUNNEL THRUSTER #2 SIGNALS FAILURE": [
    {
      name: "BOW TUNNEL THRUSTER #2 SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of Bow tunnel thruster #2 signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online Bow Tunnel Thruster 2:

1.	Fail  control power to thruster ECU from Hydraulic pp starter 1. Observe and restore.
2.	Fail  control power to thruster ECU from Hydraulic pp starter 2. Observe and restore.
3.	Fail ready signal from  TCU to DP, restore.
4.	Fail pitch order signal from DP to TCU, restore. 
5.	Fail pitch order signal from TCU to  thruster , restore. 
6.	Fail pitch feedback signal from Thruster to DP, restore. 
7.	Fail pitch feedback signal from thruster to TCU, restore.`,
      resultsExpected: `
1.	Alarm on thruster control panel, no loss of thruster.
2.	Alarm on thruster control panel, no loss of thruster.
3.	Alarm on DP, Bow Thruster 1 not ready, pitch to zero.
4.	Alarm in DP . Thruster remains selected on DP. Pitch to zero.
5.	Alarm in DP . Thruster ot of DP. 
6.	Alarm in DP . Thruster remains selected on DP. 
7.	Alarm in DP . Thruster ot of DP. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "STERN TUNNEL THRUSTER SIGNALS FAILURE": [
    {
      name: "STERN TUNNEL THRUSTER SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of Stern tunnel thruster signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online Stern Tunnel Thruster :

1.	Fail  control power to thruster ECU from Hydraulic pp starter 1. Observe and restore.
2.	Fail  control power to thruster ECU from Hydraulic pp starter 2. Observe and restore.
3.	Fail ready signal from  TCU to DP, restore.
4.	Fail pitch order signal from DP to TCU, restore. 
5.	Fail pitch order signal from TCU to  thruster , restore. 
6.	Fail pitch feedback signal from Thruster to DP, restore. 
7.	Fail pitch feedback signal from thruster to TCU, restore.`,
      resultsExpected: `
1.	Alarm on thruster control panel, no loss of thruster.
2.	Alarm on thruster control panel, no loss of thruster.
3.	Alarm on DP, Bow Thruster 1 not ready, pitch to zero.
4.	Alarm in DP . Thruster remains selected on DP. Pitch to zero.
5.	Alarm in DP . Thruster ot of DP. 
6.	Alarm in DP . Thruster remains selected on DP. 
7.	Alarm in DP . Thruster ot of DP. `,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "CPP (P) SIGNALS FAILURE": [
    {
      name: "CPP (P) SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of CPP signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For CPP (P) :

1.	Fail main 24V DC supply to propulsion control system. Restore.
2.	Fail backup 24V DC supply to propulsion control system. Restore.
3.	Fail ready signal from TCU to DP, restore.
4.	Fail pitch order signal from DP to TCU, restore.
5.	Fail pitch order signal from TCU to thruster, restore.
6.	Fail pitch feedback signal from TCU to DP, restore.
7.	Fail pitch feedback signal from thruster to TCU, restore.
`,
      resultsExpected: `1.	No alarm, no loss of propeller.
2.	No alarm, no loss of propeller.
3.	Alarm on DP, CPP not ready. Pitch to zero.
4.	Alarm on DP.  Pitch to Zero
5.	Alarm on DP. Pitch Freeze. 
6.	Alarm on DP, CPP in DP control.
7.	Alarm on DP.CPP out of DP`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "CPP (S) SIGNALS FAILURE": [
    {
      name: "CPP (S) SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of CPP signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For CPP (S)
1.	Fail main 24V DC supply to propulsion control system. Restore.
2.	Fail backup 24V DC supply to propulsion control system. Restore.
3.	Fail ready signal from TCU to DP, restore.
4.	Fail pitch order signal from DP to TCU, restore.
5.	Fail pitch order signal from TCU to thruster, restore.
6.	Fail pitch feedback signal from TCU to DP, restore.
7.	Fail pitch feedback signal from thruster to TCU, restore.
`,
      resultsExpected: `1.	No alarm, no loss of propeller.
2.	No alarm, no loss of propeller.
3.	Alarm on DP, CPP not ready. Pitch to zero.
4.	Alarm on DP.  Pitch to Zero
5.	Alarm on DP. Pitch Freeze. 
6.	Alarm on DP, CPP in DP control.
7.	Alarm on DP.CPP out of DP`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "RUDDERS (P) SIGNALS FAILURE": [
    {
      name: "RUDDERS (P) SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of Rudder signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For Rudder (P)
1.	Fail 220 V AC to No. 1 Steering Gear Controller (P). Observe result and restore
2.	Fail 220 V AC to No. 2 Steering Gear Controller (P). Observe result and restore
3.	Fail ready signal from Steering Control Unit to DP , restore.
4.	Fail rudder command signal from DP to Steering Control Unit, restore.
5.	Fail rudder feedback signal from Steering Feedback unit to DP, restore
6.	Fail rudder Command signal from Steering Control unit to the No. 1 Steering Controller (P), restore.
7.	Fail rudder Command signal from Steering Control unit to the No. 2 Steering Controller (P), restore.
8.	Fail rudder feedback signal from Steering to the Steering Control Unit, restore
9.	Fail rudder feedback signal from Steering to the No. 1 Steering Gear Controller (P). Observe result and restore. 
10.	Fail rudder feedback signal from Steering to the No. 2 Steering Gear Controller (P). Observe result and restore.`,
      resultsExpected: `1. Electric Motor No. 1 Stops. Alarm in Steering Panel. No effect on DP
2. Electric Motor No. 2 Stops. Alarm in Steering Panel. No effect on DP 
3. Alarm on DP, Rudder (P) not ready. 
4. Alarm on DP. Rudder remains on DP. Rudder angle freeze.
5. Alarm on DP. Rudder remains on DP. 
6. Alarm on Steering Gear Panel. Rudder remains on DP. 
7. Alarm on Steering Gear Panel. Rudder remains on DP.  
8. Alarm in DP . Rudder not ready.
9. Alarm on Steering Gear Panel. Rudder remains on DP.  
10. Alarm on Steering Gear Panel. Rudder remains on DP.  `,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "RUDDERS (S) SIGNALS FAILURE": [
    {
      name: "RUDDERS (S) SIGNALS FAILURE",
      equipment: "THRUSTERS & PROPULSION",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of Rudder signals",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.2",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For Rudder (S)
1.	Fail 220 V AC to No. 1 Steering Gear Controller (P). Observe result and restore
2.	Fail 220 V AC to No. 2 Steering Gear Controller (P). Observe result and restore
3.	Fail ready signal from Steering Control Unit to DP , restore.
4.	Fail rudder command signal from DP to Steering Control Unit, restore.
5.	Fail rudder feedback signal from Steering Feedback unit to DP, restore
6.	Fail rudder Command signal from Steering Control unit to the No. 1 Steering Controller (P), restore.
7.	Fail rudder Command signal from Steering Control unit to the No. 2 Steering Controller (P), restore.
8.	Fail rudder feedback signal from Steering to the Steering Control Unit, restore
9.	Fail rudder feedback signal from Steering to the No. 1 Steering Gear Controller (P). Observe result and restore. 
10.	Fail rudder feedback signal from Steering to the No. 2 Steering Gear Controller (P). Observe result and restore.`,
      resultsExpected: `1. Electric Motor No. 1 Stops. Alarm in Steering Panel. No effect on DP
2. Electric Motor No. 2 Stops. Alarm in Steering Panel. No effect on DP 
3. Alarm on DP, Rudder (P) not ready. 
4. Alarm on DP. Rudder remains on DP. Rudder angle freeze.
5. Alarm on DP. Rudder remains on DP. 
6. Alarm on Steering Gear Panel. Rudder remains on DP. 
7. Alarm on Steering Gear Panel. Rudder remains on DP.  
8. Alarm in DP . Rudder not ready.
9. Alarm on Steering Gear Panel. Rudder remains on DP.  
10. Alarm on Steering Gear Panel. Rudder remains on DP.  `,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "EMERGENCY STOPS": [
    {
      name: "EMERGENCY STOPS",
      equipment: "EMERGENCY STOPS",
      testNo: "Test No.",
      objective: "To test functionality of emergency stops",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.6",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online For each thruster in turn:
1.	Activate thruster emergency stops on Fwd. Bridge.
2.	Activate thruster emergency stop on Aft Bridge / DP console.
`,
      resultsExpected: `1.	Alarm in AMS, thruster stops.
2.	Alarm in AMS, thruster stops.

`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "MANEUVER CHANGEOVER": [
    {
      name: "MANEUVER CHANGEOVER",
      equipment: "CONTROLS  SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate smooth changeover of thrusters control positions",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Take command aft bridge location.
2.	Take command on IJS Joystick.
3.	Switch the selection switch to DP, take command on DP.
4.	Deselect DP by switch selection switch to manual.
5.	Operate controls of stations not in command (levers & DP).
`,
      resultsExpected: `1.	Command transferred to aft bridge
2.	Command transferred to IJS.
3.	Command transferred to DP.
4.	Command transferred to manual station.
5.	No effect on control of vessel.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "IJS FUNCTIONS": [
    {
      name: "IJS FUNCTIONS",
      equipment: "CONTROLS  SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the functions of IJS",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on IJS control and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Shutdown Main DP system (both Controllers & OS). Do not restore.
2.	Move the vessel by means of joystick, using sway, surge and functions in turn.
3.	Change heading using joystick (manual and auto yaw).
4.	Rotate vessel using alternate rotation center.
5.	Move vessel using “reduced” and “full” thrust modes.
6.	Restore power to main DP system (both Controllers & OS).
`,
      resultsExpected: `1.	Alarm for loss of Main DP Systems, no effect on IJS or manual controls.
2.	Vessel moves to commands.
3.	Normal operation.
4.	Satisfactory rotation about alternate rotation point.
5.	Full thrust available in full thrust mode & reduced thrust available in reduced thrust mode.
6.	Main DP system re-starts and is available to take control.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP JOYSTICK CONTROL": [
    {
      name: "DP JOYSTICK CONTROL",
      equipment: "DP  CONTROL SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the functions DP joystick",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
For each DP Operator Station and cJoy OT in turn:

1.	Move vessel by operating joystick, using sway, and surge and yaw controls in turn.
2.	Change heading using joystick (manual and auto yaw).
3.	Rotate vessel using alternate rotation centres.
4.	Move vessel using “reduced” and “full” thrust modes.
5.	Select “Environmental Compensation” mode.
6.	Move vessel in full DP Control on the DP IJS.
`,
      resultsExpected: `1.	Vessel moves to commands.
2.	Normal operation.
3.	Satisfactory rotation about alternate rotation point.
4.	Full thrust available in full thrust mode & reduced thrust available in reduced thrust mode.
5.	Appropriate power applied to thrusters to compensate for environmental conditions.
6.	Satisfactory moves executed.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP PMS CONTROL": [
    {
      name: "DP PMS CONTROL",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the functions DP blackout prevention",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online High Gain & High speed selected on joystick control:
1. In manual DP control, move joystick hard over to port then move hard over to stbd. 
`,
      resultsExpected: `1. Vessel follows joystick movements and power reduction / load shedding prevents thrusters from overloading switchboards. Alarm on DP, Power limits reached on conn swbd 1 /2`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP UPS 1 FAILURE": [
    {
      name: "DP UPS 1 FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effect of DP UPS 1 and to verify the performance of the battery",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Fail the primary power supply from 220Vac  to DP UPS 1. Do not reinstate.
2.	Run on batteries for 30minutes, observe the results and restore. Record current and battery voltage at start and end of test before restoring power. - Reinstate.
3.	Fail each distribution breaker one at a time until all MCBs are off and compare against DP UPS distribution panel list. Observe and reinstate breakers between steps and recover consumers so effects of next breaker trip can be observed.
`,
      resultsExpected: `1.	Alarm “UPS 1 comm failure” on DP operator station and the UPS operates on batteries.
2.	DP operates on batteries for 30 minutes.
3.	Loss of power to the equipment. No effect on position or heading.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Value"],
          ["Fuse", "Consumer", "Effects"],
        ],
      },
    },
  ],
  "DP UPS 2 FAILURE": [
    {
      name: "DP UPS 2 FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effect of DP UPS 2 and to verify the performance of the battery",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Fail the primary power supply from 220Vac  to DP UPS 2. Do not reinstate.
2.	Run on batteries for 30minutes, observe the results and restore. Record current and battery voltage at start and end of test before restoring power. - Reinstate.
3.	Fail each distribution breaker one at a time until all MCBs are off and compare against DP UPS distribution panel list. Observe and reinstate breakers between steps and recover consumers so effects of next breaker trip can be observed.
`,
      resultsExpected: `1.	Alarm “UPS 2 comm failure” on DP operator station and the UPS operates on batteries.
2.	DP operates on batteries for 30 minutes.
3.	Loss of power to the equipment. No effect on position or heading.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Value"],
          ["Fuse", "Consumer", "Effects"],
        ],
      },
    },
  ],
  "DP NETWORKS": [
    {
      name: "DP NETWORKS",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of DP networks hubs",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Fail Network ‘A’ Hub, observe results and restore.
2.	Fail Network ‘B’ Hub, observe results and restore.
`,
      resultsExpected: `1.	Error Net A, Process Net A disconnected, PS degraded alarms on DP. DP operating on Ethernet B.
2.	Error Net B, Process Net B disconnected, PS degraded alarms on DP. DP operating on Ethernet A.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "NETWORK STORM TEST": [
    {
      name: "NETWORK STORM TEST",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the Network failure alarms and to observe the effect on DP.",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Simulate Network Storm on Network “A”. Test protocol to be provided by the OEM
2.	Simulate Network Storm on Network “B”. Test protocol to be provided by the OEM.`,
      resultsExpected: ``,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP CONTROLLERS FAILURE": [
    {
      name: "DP CONTROLLERS FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of a DP controller and bump less changeover to backup controller",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. DP “K-pos1” is master controller.
1.	Fail DPC A – Observe results and reinstate.
2.	Fail DPC B – Observe results and reinstate.
`,
      resultsExpected: `1.	Loss of RCU A. Test done with RCU A as master. DP changeover to RCU B on loss of RCU A. Alarms “DP PS not communicational”, “Equipment DpPs01: station is not communicational” received on DP.
2.	Loss of RCU B. Test done with RCU B as master. DP changeover to RCU A on loss of RCU A. Alarms “DP PS not communicational”, “Equipment DpPs11: station is not communicational” received on DP.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP OS FAILURE": [
    {
      name: "DP OS FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the failure effects of a DP OS does not effect the DP operation.",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online. DP “K-pos1” is master controller.
1.	With OS 1 in command Simulate failure of “OS1” – Observe results and reinstate. 
2.	With OS 2 in command Simulate failure of “OS2” – Observe results and reinstate. `,
      resultsExpected: `1.	No effect on current DP operation. Control transfer to stby OS.
2.	No effect on current DP operation. Control transfer to stby OS.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "MRU SENSORS FAILURE": [
    {
      name: "MRU SENSORS FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of MRUs",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Ready signal or serial line failure: Select MRU1 as preference. Disconnect ready signal or serial line signal from sensor to DP - Reinstate.
2.	Repeat step 1 for MRU2 and MRU3.
`,
      resultsExpected: `1.	Loss of MRU1. Alarm on DP: “MRU1 not ready”, “MRU1 NMEA telegram timeout”. DP automatically select next available MRU.
2.	Loss of MRU2. Alarm on DP: “MRU2 not ready”, “MRU2 NMEA telegram timeout”. DP automatically select next available VRS.
3.	Loss of MRU3. Alarm on DP: “MRU3 not ready”, “MRU3 NMEA telegram timeout”. DP automatically select next available VRS.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "GYROCOMPASS FAILURE": [
    {
      name: "GYROCOMPASS FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of the Gyro compasses",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Gyro failure: Select Gyro 1 as preference – Simulate failure of Gyro, reinstate.
2.	Gyro Difference with Two Gyros: Deselect one Gyro. Using the offset function on one of the remaining Gyros, increase or decrease the heading until the DP system alarms. – Reinstate all Gyros.
3.	Repeat the tests  No.1 and No.2  for Gyro 2 and Gyro 3.
`,
      resultsExpected: `Gyro 1
1.	Loss of Gyro 1. Alarm on DP. DP automatically select next available gyro.
2.	Gyro 1 was deselected. Alarm on DP. DP continue using selected gyro

Gyro 2
3.	Loss of Gyro 2. Alarm on DP. DP automatically select next available gyro.
4.	 Gyro 2 was deselected. Alarm on DP. DP continue using selected gyro

Gyro 3
5.	Loss of Gyro 3. Alarm on DP. DP automatically select next available gyro.
6.	 Gyro 3 was deselected. Alarm on DP. DP continue using selected gyro
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "WIND SENSORS FAILURE": [
    {
      name: "WIND SENSORS FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of wind sensors",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online


1.	Serial line failure: Select wind sensor 1 as preference. Disconnect serial line signal from sensor to DP - Reinstate.
2.	Repeat the above step 1 for wind sensor 2. Wind sensor under test to be placed as preference.
`,
      resultsExpected: `1.	Loss of wind sensor 1. Alarm on DP. DP automatically select next available wind sensor.
2.	Loss of wind sensor 2. Alarm on DP. DP automatically select next available wind sensor
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DP CONSEQUENCE ALARM TEST": [
    {
      name: "DP CONSEQUENCE ALARM TEST",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To verify the operation of the consequence analysis",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online Enable consequence analysis running in Class 2 mode.

1.	Deselect one BT until the alarm ‘Consequence Analysis Drift off Warning’ is issued by the DP system – reinstate thrusters and stabilise DP position and heading.
2.	Deselect one CPP until the alarm ‘Consequence Analysis Drift off Warning’ is issued by the DP system – reinstate thrusters and stabilise DP position and heading.
`,
      resultsExpected: `1.	“Consequence Analysis Drift off Warning” issued by the DP system.
2.	“Consequence Analysis Drift off Warning” issued by the DP system.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "HEADING CONTROL & HEADING/POSITION OUT OF LIMITS": [
    {
      name: "HEADING CONTROL & HEADING/POSITION OUT OF LIMITS",
      equipment: "DP CONTROL SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the vessel has good heading control on each Gyrocompass and verified heading out of limits alarms on DP",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Gyro 1 in use, change heading +30º check accurately heading control with minimal overshoot.
2.	Gyro 2 in use, change heading - 60º check accurately heading control with minimal overshoot.
3.	Gyro 3 in use, change heading +30º check accurately heading control with minimal overshoot.
4.	Note vessel’s heading difference from set point. In heading out of limits “alarm”, set trigger point lower than difference. Following, set “warning” trigger point lower than “alarm” detection.
`,
      resultsExpected: `1.	Accurate heading control with minimal overshoot.
2.	Accurate heading control with minimal overshoot.
3.	Accurate heading control with minimal overshoot. Vessel back in original heading.
4.	‘Heading out of limits’ warning and alarm reported on DP system respectively.`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Value 1", "Value 2"],
          ["Gyro", "+30°", "-60°", "+30°"],
        ],
      },
    },
  ],
  "ROTATION CHECK": [
    {
      name: "ROTATION CHECK",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To verify the performance and offsets of all position reference systems",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1. Command vessel to rotate 360 degrees with interval of 30 degrees to permit vessel to stabilize position and model before continuation of rotation.
`,
      resultsExpected: `1. Environmental forces remain in same general direction as vessel rotates.
(Some movement of current force may be expected during vessel move. Force should be resolved as vessel settles on each new heading)

All position reference sensors remain at same relative position with respect to each other as vessel rotates.

All position reference sensors remain within acceptance window as vessel rotates.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "MATHEMATICAL MODEL TEST": [
    {
      name: "MATHEMATICAL MODEL TEST",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To demonstrate the DP system ability to maintain position using only the mathematical model with gyros, wind sensors and VRUs only",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
Allow Mathematical model to build until the integral component has stabilized with no thruster commands at least for 15-minute period. Deselect all Position Reference Systems (PRSs) and monitor position using an independent PRS over a 5-minute period. If possible, select a waypoint at original position on DGNSS and note range and bearing. Alternatively, use UTM coordinates and note heading, northings and eastings at one-minute intervals.
1. Note environmental conditions, deselect all PRSs and note position below.
`,
      actualResults:
        "1. Deselected DGPS 1 and DGPS 2. Alarms on DP, “No reference system enabled”, “PS Degraded”, and “Position dropout”.",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Value"],
          ["Start", "Position N", "Position E", "Overshoot(m)"],
        ],
      },
    },
  ],
  "PRS WEIGHTAGE": [
    {
      name: "PRS WEIGHTAGE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective:
        "To verify DP software weighting routines & position keeping with different PRS combinations",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online
1.	Degrade each one by one and restore. Observe weighting changes and performance
2.	Test DP position / heading keeping capability with different combinations of PRS
`,
      resultsExpected: `1.	Weighting matched performance
2.	DP position / heading capability stable, no jump in position and heading.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DGPS 1 POSITIONING": [
    {
      name: "DGPS 1 POSITIONING",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To verify position keeping performance of DGPS 1",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `Vessel on full DP Control. All thrusters engaged

1.	Using DGPS1 only, command vessel 20 meters forward.
2.	Command vessel 20 meters Port
3.	Command vessel 20 meters astern
4.	Command vessel 20 meters Stbd
`,
      resultsExpected: `1.	Vessel moves to new location.
2.	Vessel moves to new location.
3.	Vessel moves to new location.
4.	Vessel returns to original location.
NOTE: Overshoot less than 5m heading, deviation less than 3 deg.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Knots", "Degree"],
          ["Start", "Position N", "Position E", "Overshoot(m)"],
        ],
      },
    },
  ],
  "DGPS 1 FAILURE": [
    {
      name: "DGPS 1 FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of DGPS 1",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Disconnect GPS antenna from DGPS 1 receiver– Reinstate.
2.	Disconnect sources of corrections to DGPS 1. Record alarms for loss of corrections. Reinstate.
3.	Disconnect serial signal to DP - Reinstate. 
`,
      resultsExpected: `1.	Loss of DGPS 1. Alarm on DP.
2.	Alarm on DP. DGPS 1 rejected by DP.
3.	Loss of DGPS 1. Alarm on DP. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "DGPS 2 POSITIONING": [
    {
      name: "DGPS 2 POSITIONING",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To verify position keeping performance of DGPS 2",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `Vessel on full DP Control. All thrusters engaged

1.	Using DGPS1 only, command vessel 20 meters forward.
2.	Command vessel 20 meters Port
3.	Command vessel 20 meters astern
4.	Command vessel 20 meters Stbd
`,
      resultsExpected: `1.	Vessel moves to new location.
2.	Vessel moves to new location.
3.	Vessel moves to new location.
4.	Vessel returns to original location.
NOTE: Overshoot less than 5m heading, deviation less than 3 deg.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Knots", "Degree"],
          ["Start", "Position N", "Position E", "Overshoot(m)"],
        ],
      },
    },
  ],
  "DGPS 2 FAILURE": [
    {
      name: "DGPS 1 FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of DGPS 2",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Disconnect GPS antenna from DGPS 2 receiver– Reinstate.
2.	Disconnect sources of corrections to DGPS 2. Record alarms for loss of corrections. Reinstate.
3.	Disconnect serial signal to DP - Reinstate. 
`,
      resultsExpected: `1.	Loss of DGPS 2. Alarm on DP.
2.	Alarm on DP. DGPS 2 rejected by DP.
3.	Loss of DGPS 2. Alarm on DP. 
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "SoptTrack POSITIONING": [
    {
      name: "SoptTrack POSITIONING",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To verify position keeping performance of SoptTrack",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `Vessel on full DP Control. All thrusters engaged

1.	Using SoptTrack only, command vessel 10 meters astern.
2.	Command vessel 10 meters starboard
3.	Command vessel 10 meters forward
4.	Command vessel 10 meters port
`,
      resultsExpected: `1.	Vessel moves to new location.
2.	Vessel moves to new location.
3.	Vessel moves to new location.
4.	Vessel returns to original location.
NOTE: Overshoot less than 5m heading deviation less than 3 deg.
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
      includeTableMethod: false,
      includeTableExpected: false,
      includeTableActual: true,
      includeTableComments: false,
      tableHeaders: {
        actual: [
          ["Key", "Knots", "Degree"],
          ["Start", "Position N", "Position E", "Overshoot(m)"],
        ],
      },
    },
  ],
  "SoptTrack FAILURE": [
    {
      name: "SoptTrack FAILURE",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the failure effects of SoptTrack",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.4",
      method: `With vessel on DP and all main engines online, all thrusters’ / DP sensors / PRS online

1.	Remove Power from DP UPS 1  to SoptTrack laser - Reinstate.
2.	Disconnect serial link from SoptTrack to DP – Reinstate.
`,
      resultsExpected: `
1.	SoptTrack rejected by DP. Alarm on DP. 
2.	SoptTrack was rejected by DP. Alarm on DP. DP system continue to operate with remaining PRS
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
  "COMMUNICATION SYSTEMS": [
    {
      name: "COMMUNICATION SYSTEMS",
      equipment: "DP CONTROLS SYSTEM",
      testNo: "Test No.",
      objective: "To demonstrate the effectiveness of voice communication.",
      fmeaRef: "Doc. No. AMCA FMEA/2024/57 Section: 9.6",
      method: `Test and record all types of voice communications between the following location

1.	Communication equipment between bridge and control stations (ECR, Bow thruster room etc.) to be tested. Test to include raising call in each direction.
2.	Transmit SAT C and observe the performance of DGPS
`,
      resultsExpected: `1.	Clear communication.
2.	Transmission of SAT C should not be affecting the performance of DGPS
`,
      actualResults: "",
      comments: "",
      witnessedBy1: "",
      date1: "",
      witnessedBy2: "",
      date2: "",
    },
  ],
};

export const testOptions = [
  { value: "SOFTWARE AUDIT", label: "SOFTWARE AUDIT" },
  { value: "FUEL OIL SYSTEM", label: "FUEL OIL SYSTEM" },
  { value: "COOLING WATER SYSTEM", label: "COOLING WATER SYSTEM" },
  { value: "LUBE OIL SYSTEM", label: "LUBE OIL SYSTEM" },
  { value: "COMPRESSED AIR SYSTEM", label: "COMPRESSED AIR SYSTEM" },
  { value: "ENGINES CONTROL SUPPLY", label: "ENGINES CONTROL SUPPLY" },
  {
    value: "FAILURE OF PORT MAIN ENGINE",
    label: "FAILURE OF PORT MAIN ENGINE",
  },
  {
    value: "FAILURE OF STBD MAIN ENGINE",
    label: "FAILURE OF STBD MAIN ENGINE",
  },
  {
    value: "MAIN ENGINES FULL LOAD TEST",
    label: "MAIN ENGINES FULL LOAD TEST",
  },
  {
    value: "690VAC Main SWBD Bus HA",
    label: "690VAC Main SWBD Bus HA",
  },
  {
    value: "690VAC Main SWBD Bus HB",
    label: "690VAC Main SWBD Bus HB",
  },
  {
    value: "440VAC Main Bus 1  FAILURE",
    label: "440VAC Main Bus 1  FAILURE",
  },
  {
    value: "440VAC Main Bus 2  FAILURE",
    label: "440VAC Main Bus 2  FAILURE",
  },
  {
    value: "440VAC SG 1 Bus  FAILURE",
    label: "440VAC SG 1 Bus  FAILURE",
  },
  {
    value: "440VAC SG 2 Bus  FAILURE",
    label: "440VAC SG 2 Bus  FAILURE",
  },
  {
    value: "440VAC EMERGENCY SWITCHBOARD",
    label: "440VAC EMERGENCY SWITCHBOARD",
  },
  {
    value: "220VAC MSB 1 (PORT)",
    label: "220VAC MSB 1 (PORT)",
  },
  {
    value: "220VAC MSB 2 (STBD)",
    label: "220VAC MSB 2 (STBD)",
  },
  {
    value: "220VAC ESB",
    label: "220VAC ESB",
  },
  {
    value: "24VDC SYSTEM – BATTERY CHARGER for DC11,DC22,DC33 &DC44",
    label: "24VDC SYSTEM – BATTERY CHARGER for DC11,DC22,DC33 &DC44",
  },
  {
    value: "AMS FAILURE",
    label: "AMS FAILURE",
  },
  {
    value: "THRUSTER & MAIN PROPULSION FULL LOAD TEST",
    label: "THRUSTER & MAIN PROPULSION FULL LOAD TEST",
  },
  {
    value: "CPP HYDRAULIC FAILURE",
    label: "CPP HYDRAULIC FAILURE",
  },
  {
    value: "STEERING GEARS FAILURES",
    label: "STEERING GEARS FAILURES",
  },
  {
    value: "BOW TUNNEL THRUSTER #1 SIGNALS FAILURE",
    label: "BOW TUNNEL THRUSTER #1 SIGNALS FAILURE",
  },
  {
    value: "BOW TUNNEL THRUSTER #2 SIGNALS FAILURE",
    label: "BOW TUNNEL THRUSTER #2 SIGNALS FAILURE",
  },
  {
    value: "STERN TUNNEL THRUSTER SIGNALS FAILURE",
    label: "STERN TUNNEL THRUSTER SIGNALS FAILURE",
  },
  {
    value: "CPP (P) SIGNALS FAILURE",
    label: "CPP (P) SIGNALS FAILURE",
  },
  {
    value: "CPP (S) SIGNALS FAILURE",
    label: "CPP (S) SIGNALS FAILURE",
  },
  {
    value: "RUDDERS (P) SIGNALS FAILURE",
    label: "RUDDERS (P) SIGNALS FAILURE",
  },
  {
    value: "RUDDERS (S) SIGNALS FAILURE",
    label: "RUDDERS (S) SIGNALS FAILURE",
  },
  {
    value: "EMERGENCY STOPS",
    label: "EMERGENCY STOPS",
  },
  {
    value: "MANEUVER CHANGEOVER",
    label: "MANEUVER CHANGEOVER",
  },
  {
    value: "IJS FUNCTIONS",
    label: "IJS FUNCTIONS",
  },
  {
    value: "DP JOYSTICK CONTROL",
    label: "DP JOYSTICK CONTROL",
  },
  {
    value: "DP PMS CONTROL",
    label: "DP PMS CONTROL",
  },
  {
    value: "DP UPS 1 FAILURE",
    label: "DP UPS 1 FAILURE",
  },
  {
    value: "DP UPS 2 FAILURE",
    label: "DP UPS 2 FAILURE",
  },
  {
    value: "DP NETWORKS",
    label: "DP NETWORKS",
  },
  {
    value: "NETWORK STORM TEST",
    label: "NETWORK STORM TEST",
  },
  {
    value: "DP CONTROLLERS FAILURE",
    label: "DP CONTROLLERS FAILURE",
  },
  {
    value: "DP OS FAILURE",
    label: "DP OS FAILURE",
  },
  {
    value: "MRU SENSORS FAILURE",
    label: "MRU SENSORS FAILURE",
  },
  {
    value: "GYROCOMPASS FAILURE",
    label: "GYROCOMPASS FAILURE",
  },
  {
    value: "WIND SENSORS FAILURE",
    label: "WIND SENSORS FAILURE",
  },
  {
    value: "DP CONSEQUENCE ALARM TEST",
    label: "DP CONSEQUENCE ALARM TEST",
  },
  {
    value: "HEADING CONTROL & HEADING/POSITION OUT OF LIMITS",
    label: "HEADING CONTROL & HEADING/POSITION OUT OF LIMITS",
  },
  {
    value: "ROTATION CHECK",
    label: "ROTATION CHECK",
  },
  {
    value: "MATHEMATICAL MODEL TEST",
    label: "MATHEMATICAL MODEL TEST",
  },
  {
    value: "PRS WEIGHTAGE",
    label: "PRS WEIGHTAGE",
  },
  {
    value: "DGPS 1 POSITIONING",
    label: "DGPS 1 POSITIONING",
  },
  {
    value: "DGPS 1 FAILURE",
    label: "DGPS 1 FAILURE",
  },
  {
    value: "DGPS 2 POSITIONING",
    label: "DGPS 2 POSITIONING",
  },
  {
    value: "DGPS 2 FAILURE",
    label: "DGPS 2 FAILURE",
  },
  {
    value: "SoptTrack POSITIONING",
    label: "SoptTrack POSITIONING",
  },
  {
    value: "SoptTrack FAILURE",
    label: "SoptTrack FAILURE",
  },
];

// Define static table data for specific test types
export const staticTableData = {
  "DP UPS 1 FAILURE": {
    actual: {
      "actual-0": {
        headers: ["Key", "Value"],
        rows: [
          ["Load Current", ""],
          ["Load kW", ""],
          ["Start Time", ""],
          ["Completion Time", ""],
          ["Start Voltage (battery)", ""],
          ["Completion Voltage (battery)", ""],
        ],
      },
      "actual-1": {
        headers: ["Fuse", "Consumer", "Effects"],
        rows: [
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
        ],
      },
    },
  },
  "DP UPS 2 FAILURE": {
    actual: {
      "actual-0": {
        headers: ["Key", "Value"],
        rows: [
          ["Load Current", ""],
          ["Load kW", ""],
          ["Start Time", ""],
          ["Completion Time", ""],
          ["Start Voltage (battery)", ""],
          ["Completion Voltage (battery)", ""],
        ],
      },
      "actual-1": {
        headers: ["Fuse", "Consumer", "Effects"],
        rows: [
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
        ],
      },
    },
  },
  "HEADING CONTROL & HEADING/POSITION OUT OF LIMITS": {
    actual: {
      "actual-0": {
        headers: ["Key", "Value 1", "Value 2"],
        rows: [
          ["Environmental force & direction", "", ""],
          ["Wind speed & direction", "", ""],
        ],
      },
      "actual-1": {
        headers: ["Gyro", "+30°", "-60°", "+30°"],
        rows: [
          ["1", "", "", ""],
          ["2", "", "", ""],
          ["3", "", "", ""],
        ],
      },
    },
  },
  "DGPS 1 POSITIONING": {
    actual: {
      "actual-0": {
        headers: ["Key", "Knots", "Degree"],
        rows: [
          ["Environmental force & direction", "", ""],
          ["Wind speed & direction", "", ""],
        ],
      },
      "actual-1": {
        headers: ["", "Position N", "Position E", "Overshoot(m)"],
        rows: [
          ["0 Start", "", "", ""],
          ["1", "", "", ""],
          ["2", "", "", ""],
          ["3", "", "", ""],
          ["4 End", "", "", ""],
        ],
      },
    },
  },
  "DGPS 2 POSITIONING": {
    actual: {
      "actual-0": {
        headers: ["Key", "Knots", "Degree"],
        rows: [
          ["Environmental force & direction", "", ""],
          ["Wind speed & direction", "", ""],
        ],
      },
      "actual-1": {
        headers: ["", "Position N", "Position E", "Overshoot(m)"],
        rows: [
          ["0 Start", "", "", ""],
          ["1", "", "", ""],
          ["2", "", "", ""],
          ["3", "", "", ""],
          ["4 End", "", "", ""],
        ],
      },
    },
  },
  "SoptTrack POSITIONING": {
    actual: {
      "actual-0": {
        headers: ["Key", "Knots", "Degree"],
        rows: [
          ["Environmental force & direction", "", ""],
          ["Wind speed & direction", "", ""],
        ],
      },
      "actual-1": {
        headers: ["", "Position N", "Position E", "Overshoot(m)"],
        rows: [
          ["0 Start", "", "", ""],
          ["1", "", "", ""],
          ["2", "", "", ""],
          ["3", "", "", ""],
          ["4 End", "", "", ""],
        ],
      },
    },
  },
  "MATHEMATICAL MODEL TEST": {
    actual: {
      "actual-0": {
        headers: ["Key", "Value"],
        rows: [
          ["Depth (in metre)", ""],
          ["Current (in Knots)", ""],
          ["Wind (in Knots)", ""],
          ["Wind speed & direction (in Degree)", ""],
        ],
      },
      "actual-1": {
        headers: ["Start", "Position N", "Position E", "Overshoot(m)"],
        rows: [
          ["0", "", "", ""],
          ["1", "", "", ""],
          ["2", "", "", ""],
          ["3", "", "", ""],
          ["4", "", "", ""],
          ["5", "", "", ""],
        ],
      },
    },
  },
  // Add more test types and their static tables as needed
};
