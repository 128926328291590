import React, { useEffect, useRef, useState } from "react";
import "./styles.scss"; // Ensure you include this CSS file
// import FormHeader from "../../header";
import FormFooter from "../../footer";
import img1 from "../../../../assets/images/front.png";
import img2 from "../../../../assets/images/front2.png";
import { renderInputOrText } from "../../../../helpers/renderInputOrText";
import TextInput from "../../../atoms/TextInput";
import { DateSelector } from "../../../molecules/MonthYearSelector";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import FMEATest from "../../../organisms/FMEATest/index";
import Delete from "../../../icons/Delete";
import CustomButton from "../../../atoms/CustomButton";

const Overview = ({
  roleType,
  previewMode = false,
  navigationClicked,
  setAMCAFrontPage,
  amcaFrontPage,
  amendmentRecords,
  setAmendmentRecords,
  docRevRecords,
  setDocRevRecords,
  setCrewData,
  crewData,
  setSummaryData,
  summaryData,
  disclaimerData,
  setDisclaimerData,
  instructionData,
  setInstructionData,
  orderOfTrial,
  setOrderOfTrial,
  refAndAssPub,
  setRefAndAssPub,
  scopeOfWork,
  setScopeOfWork,
  reqDuringTest,
  setReqDuringTest,
  VesselGeneral,
  setVesselGeneral,
  engineAndAuxiliary,
  setEngineAndAuxiliary,
  setThrusterPropulsion,
  thrusterPropulsion,
  electricDistribution,
  setElectricDitribution,
  equipmentRows,
  setEquipmentRows,
  setSensorRows,
  sensorRows,
  setPrsRows,
  prsRows,
  machineConfDuringTest,
  setMachineConfDuringTest,
  worstCaseFailure,
  setWorstCaseFailure,
  dpSystemMaintain,
  setDpSystemMaintain,
  generalRemarks,
  setGeneralRemarks,
  dpComputers,
  setDpComputers,
  batteryMaintenance,
  setbatteryMaintenance,
  checkListDP,
  setCheckListDP,
  checkListDPRemarks,
  setCheckListDPRemarks,
  hardwareModification,
  setHardWareModification,
  capabilityPlots,
  setCapabilityPlots,
  dpIncident,
  setDPIncident,
  dpIncidentRemarks,
  setDpIncidentRemarks,
  dpTraningPersonal,
  setDPTrainingPersonal,
  dpTraningPersonalRemarks,
  setDpTraningPersonalRemarks,
  setPreTrialCheck,
  preTrialCheck,
  setSwitchboardMaintenance,
  switchboardMaintenance,
  preTrialCheckremarks,
  setPreTrialCheckRemarks,
  machineryMaintenanceColumns,
  setMachineryMaintenanceColumns,
  machineryMaintenanceRows,
  setMachineryMaintenanceRows,
  machineryMaintainenceRemarks,
  setMachineryMaintainenceRemarks,
  mainEnginesRows,
  setMainEnginesRows,
  mainEnginesColumns,
  setMainEnginesColumns,
  mainEnginesRemarks,
  setMainEnginesRemarks,
  setContent,
  content,
  editorValueDpTrialTestSchedule,
  setEditorValueDpTrialTestSchedule,
  setVesselGeneralTableData,
  vesselGeneralTableData,
  setSummaryDataState,
  summaryDataState,
  setInstructionDataState,
  instructionDataState,
  setOrderOfTrialState,
  orderOfTrialState,
  setVesselGeneralState,
  VesselGeneralState,
  testDetails,
  setTestDetails,
  editorValueObservationsCloseOut,
  setEditorValueObservationsCloseOut,
  tableData,
  setTableData,
}) => {
  const divRefDisclaimer = useRef(null);
  const divRefSummary = useRef(null);
  const divRefInstruction = useRef(null);
  const divRefOrderOfTrial = useRef(null);
  const divRefScopeOfWork = useRef(null);
  const divRefRefAndAsstPub = useRef(null);
  const divRefReqDuringTest = useRef(null);
  const divRefVesselGeneral = useRef(null);
  const divRefMachineConfDuringTest = useRef(null);
  const divRefWorstCaseFailure = useRef(null);

  useEffect(() => {
    const handleScrollToSection = (id) => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" }); // Smooth scroll to the section
      }
    };
    handleScrollToSection(navigationClicked);
  }, [navigationClicked]);

  // Dummy data for Document Revision Record Table

  const handleAddRow = (table) => {
    if (table === "docRev") {
      setDocRevRecords([...docRevRecords, {}]);
    } else if (table === "amendment") {
      setAmendmentRecords([...amendmentRecords, {}]);
    }
  };

  const handleRemoveRow = (table, index) => {
    if (table === "docRev") {
      setDocRevRecords(docRevRecords.filter((_, i) => i !== index));
    } else if (table === "amendment") {
      setAmendmentRecords(amendmentRecords?.filter((_, i) => i !== index));
    }
  };

  const handleChange = (table, index, field, value) => {
    if (table === "docRev") {
      const updatedRecords = docRevRecords?.map((record, i) =>
        i === index ? { ...record, [field]: value } : record,
      );
      setDocRevRecords(updatedRecords);
    } else if (table === "amendment") {
      const updatedRecords = amendmentRecords?.map((record, i) =>
        i === index ? { ...record, [field]: value } : record,
      );
      setAmendmentRecords(updatedRecords);
    }
  };

  const handleInputChange = (index, key, value) => {
    const updatedRows = [...engineAndAuxiliary];
    updatedRows[index][key] = value;
    setEngineAndAuxiliary(updatedRows);
  };

  const addRow1 = () => {
    setEngineAndAuxiliary([
      ...engineAndAuxiliary,
      {
        me_number: "",
        description: "",
        rated_power: "",
        rated_speed: "",
        model: "",
      },
    ]);
  };

  const removeRow1 = (index) => {
    const updatedRows = engineAndAuxiliary.filter((_, i) => i !== index);
    setEngineAndAuxiliary(updatedRows);
  };

  // Add a new empty row
  const addRow = () => {
    setCrewData([...crewData, { position: "", name: null }]);
  };

  // Delete a row based on index
  const deleteRow = (index) => {
    const updatedData = crewData.filter((_, i) => i !== index);
    setCrewData(updatedData);
  };

  // Update the position field for the crew member
  const handlePositionChange = (index, value) => {
    const updatedData = [...crewData];
    updatedData[index].position = value;
    setCrewData(updatedData);
  };

  // Update the name field for the crew member
  const handleNameChange = (index, value) => {
    const updatedData = [...crewData];
    updatedData[index].name = value;
    setCrewData(updatedData);
  };

  const handleInputChange2 = (index, key, value) => {
    const updatedRows = [...thrusterPropulsion];
    updatedRows[index][key] = value;
    setThrusterPropulsion(updatedRows);
  };

  const addRow2 = () => {
    setThrusterPropulsion([
      ...thrusterPropulsion,
      {
        id: `T${thrusterPropulsion.length + 1}`,
        type_and_location: "",
        pitch: "",
        propeller_speed: "",
        model: "",
        drive: "",
      },
    ]);
  };

  const removeRow2 = (index) => {
    const updatedRows = thrusterPropulsion.filter((_, i) => i !== index);
    setThrusterPropulsion(updatedRows);
  };

  const handleInputChange3 = (index, key, value) => {
    const updatedRows3 = [...electricDistribution];
    updatedRows3[index][key] = value;
    setElectricDitribution(updatedRows3);
  };

  const addRow3 = () => {
    setElectricDitribution([
      ...electricDistribution,
      {
        id: `Row${electricDistribution.length + 1}`,
        switch_board: "",
        generator: "",
        engine: "",
        control: "",
      },
    ]);
  };

  const removeRow3 = (index) => {
    const updatedRows3 = electricDistribution.filter((_, i) => i !== index);
    setElectricDitribution(updatedRows3);
  };

  const addRow4 = (category) => {
    if (category === "equipment") {
      setEquipmentRows([...equipmentRows, { equipment: "", make_model: "" }]);
    } else if (category === "prs") {
      setPrsRows([...prsRows, { equipment: "", make_model: "" }]);
    } else if (category === "sensors") {
      setSensorRows([...sensorRows, { equipment: "", make_model: "" }]);
    }
  };

  const removeRow4 = (category, index) => {
    if (category === "equipment") {
      const updatedRows = [...equipmentRows];
      updatedRows.splice(index, 1); // Remove the row at the specified index
      setEquipmentRows(updatedRows);
    } else if (category === "prs") {
      const updatedRows = [...prsRows];
      updatedRows.splice(index, 1);
      setPrsRows(updatedRows);
    } else if (category === "sensors") {
      const updatedRows = [...sensorRows];
      updatedRows.splice(index, 1);
      setSensorRows(updatedRows);
    }
  };

  const handleInputChange4 = (category, index, key, value) => {
    let updatedRows;
    if (category === "equipment") {
      updatedRows = [...equipmentRows];
      updatedRows[index][key] = value;
      setEquipmentRows(updatedRows);
    } else if (category === "prs") {
      updatedRows = [...prsRows];
      updatedRows[index][key] = value;
      setPrsRows(updatedRows);
    } else if (category === "sensors") {
      updatedRows = [...sensorRows];
      updatedRows[index][key] = value;
      setSensorRows(updatedRows);
    }
  };

  const [editorValueA, setEditorValueA] = useState("");
  const [editorValueB, setEditorValueB] = useState("");
  const [editorValueC, setEditorValueC] = useState("");

  const handleChangeRTEDpTrialTestSchedule = (value) => {
    setEditorValueDpTrialTestSchedule(value);
  };

  const handleChangeRTEA = (value) => {
    setEditorValueA(value);
  };
  const handleChangeRTEB = (value) => {
    setEditorValueB(value);
  };
  const handleChangeRTEC = (value) => {
    setEditorValueC(value);
  };

  const handleChangeObservationsCloseOut = (value) => {
    setEditorValueObservationsCloseOut(value);
  };

  // Add a new row with default label and value
  const addRow5 = () => {
    setPreTrialCheck([...preTrialCheck, { label: "New Row", value: "" }]);
  };

  // Remove a specific row by index
  const removeRow5 = (indexToRemove) => {
    const newRows = preTrialCheck.filter((_, index) => index !== indexToRemove);
    setPreTrialCheck(newRows);
  };

  // Add a new row
  const addRow6 = () => {
    setDPTrainingPersonal([
      ...dpTraningPersonal,
      {
        position: `New Position ${dpTraningPersonal.length + 1}`,
        certificateType: "",
        certificateNumber: "",
        dateIssued: "",
        issuedBy: "",
      },
    ]);
  };

  // Remove a specific row by index
  const removeRow6 = (indexToRemove) => {
    const newRows = dpTraningPersonal.filter(
      (_, index) => index !== indexToRemove,
    );
    setDPTrainingPersonal(newRows);
  };

  const handleDPIncidentRemarksChange = (e) => {
    setDpIncidentRemarks(e.target.value);
  };
  // Add a new row
  const addRow7 = () => {
    setDPIncident([...dpIncident, { question: "New Question", details: "" }]);
  };

  // Remove a specific row by index
  const removeRow7 = (indexToRemove) => {
    const newRows = dpIncident.filter((_, index) => index !== indexToRemove);
    setDPIncident(newRows);
  };

  const handleCapabilityLabelChange = (e, index) => {
    const newRows = [...capabilityPlots];
    newRows[index].label = e.target.value;
    setCapabilityPlots(newRows);
  };

  const handleCapabilityValueChange = (e, index) => {
    const newRows = [...capabilityPlots];
    newRows[index].value = e.target.value;
    setCapabilityPlots(newRows);
  };

  // Add a new row
  const addRow8 = () => {
    setCapabilityPlots([
      ...capabilityPlots,
      { label: "New Label", value: "" }, // Default structure for a new row
    ]);
  };

  // Remove a specific row by index
  const removeRow8 = (indexToRemove) => {
    const newRows = capabilityPlots.filter(
      (_, index) => index !== indexToRemove,
    );
    setCapabilityPlots(newRows);
  };

  const handleHardwareLabelChange = (e, index) => {
    const newRows = [...hardwareModification];
    newRows[index].label = e.target.value;
    setHardWareModification(newRows);
  };

  const handleHardwareValueChange = (e, index) => {
    const newRows = [...hardwareModification];
    newRows[index].value = e.target.value;
    setHardWareModification(newRows);
  };

  // Add a new row
  const addRow9 = () => {
    setHardWareModification([
      ...hardwareModification,
      { label: "New Label", value: "" }, // Add a new row with a default structure
    ]);
  };
  // Remove a specific row by index
  const removeRow9 = (indexToRemove) => {
    const newRows = hardwareModification.filter(
      (_, index) => index !== indexToRemove,
    );
    setHardWareModification(newRows);
  };

  // // Add a new row
  // const addRow10 = () => {
  //   setCheckListDP([...checkListDP, "New Document"]);
  // };

  // // Remove a specific row by index
  // const removeRow10 = (indexToRemove) => {
  //   const newRows = checkListDP.filter((_, index) => index !== indexToRemove);
  //   setCheckListDP(newRows);
  // };

  // Initial state with predefined rows as an array of objects with label and value

  const [remarks, setRemarks] = useState(""); // State for remarks

  // Handler for input change for label
  const handleLabelCheckListDPChange = (index, newLabel) => {
    const updatedRows = [...checkListDP];
    updatedRows[index].label = newLabel;
    setCheckListDP(updatedRows);
  };

  // Handler for input change for value
  const handleValueCheckListDPChange = (index, newValue) => {
    const updatedRows = [...checkListDP];
    updatedRows[index].value = newValue;
    setCheckListDP(updatedRows);
  };

  // Add a new row
  const addRow10 = () => {
    setCheckListDP([...checkListDP, { label: "", value: "" }]);
  };

  // Remove a row
  const removeRow10 = (index) => {
    const updatedRows = checkListDP.filter((_, i) => i !== index);
    setCheckListDP(updatedRows);
  };

  // Add a new row
  const addRow11 = () => {
    setbatteryMaintenance([
      ...batteryMaintenance,
      {
        unit: `DP UPS ${batteryMaintenance.length + 1}`,
        installed: "",
        last_discharge_test: "",
        remarks: "",
      },
    ]);
  };

  // Remove a specific row by index
  const removeRow11 = (indexToRemove) => {
    const newRows = batteryMaintenance.filter(
      (_, index) => index !== indexToRemove,
    );
    setbatteryMaintenance(newRows);
  };

  // Add a new row
  const addRow12 = () => {
    // Add new row before the "Remarks" row
    const newRows = [
      ...dpComputers.slice(0, -1),
      { label: `New Row ${dpComputers.length}`, value: "" },
      dpComputers[dpComputers.length - 1],
    ];
    setDpComputers(newRows);
  };

  // Remove a specific row by index
  const removeRow12 = (indexToRemove) => {
    // Remove row only if it's not the last row (Remarks row)
    if (indexToRemove < dpComputers.length - 1) {
      const newRows = dpComputers.filter((_, index) => index !== indexToRemove);
      setDpComputers(newRows);
    }
  };

  //------------------------------------------------------

  // Initial columns and rows state
  const [columns14, setColumns14] = useState([
    { id: "1", label: "BT 1", width: "20%" },
    { id: "2", label: "BT 2", width: "20%" },
    { id: "3", label: "ST", width: "20%" },
    { id: "4", label: "Port CPP", width: "20%" },
    { id: "5", label: "Stbd CPP", width: "20%" },
  ]);

  const [rows14, setRows14] = useState([
    ["Maintenance record checked", "", "", "", ""],
    ["Outstanding maintenance", "", "", "", ""],
    ["Last oil analysis (date)", "", "", "", ""],
    ["Last oil analysis result", "", "", "", ""],
    ["Running hours at present", "", "", "", ""],
    ["Last major overhaul", "", "", "", ""],
    ["Next major overhaul", "", "", "", ""],
    ["Remarks :", ""],
  ]);

  const addColumn14 = () => {
    // Add a new column to columns14
    const newColumn = {
      id: columns14.length + 1,
      label: `New Column`,
      width: "auto",
    };
    setColumns14([...columns14, newColumn]);

    // Add an empty cell for the new column in every row of rows14
    const updatedRows = rows14.map((row) => [...row, ""]);
    setRows14(updatedRows);
  };

  const removeColumn14 = (index) => {
    if (columns14.length > 1) {
      // Prevent removing the last column
      setColumns14(columns14.filter((_, i) => i !== index));
      setRows14(rows14.map((row) => row.filter((_, i) => i !== index)));
    }
  };

  // Function to handle change in the respective RTEs
  const handleContentChange = (field, value) => {
    setContent((prevContent) => ({ ...prevContent, [field]: value }));
  };

  // Conditionally render RTE for 'surveyor', or blank space for 'backoffice'
  const renderEditorOrSpace = (field) => {
    if (roleType === "Surveyor") {
      return (
        <ReactQuill
          value={content[field]}
          onChange={(value) => handleContentChange(field, value)}
        />
      );
    }
    return <div style={{ minHeight: "100px" }}></div>; // Blank space for backoffice
  };

  const handleChangeSummary = (part, index, newValue) => {
    setSummaryDataState((prev) => ({
      ...prev,
      [part]: newValue,
    }));
  };

  const tempObj = {
    0: "Enter Requestor",
    1: "Enter Client Name",
    2: "Enter Vessel Name",
    3: "Enter Certified Person Name",
    4: "Enter Location",
    5: "Enter Trials Location",
    6: "Enter Date",
    7: "Enter AMCA Witness",
    8: "Enter IRS Witness",
  };

  const renderSummaryWithEditableFields = (text) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);

    let inputFieldIndex = 0; // Keep track of the input field's index

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const currentIndex = inputFieldIndex; // Capture the current input field index

        inputFieldIndex++; // Increment for the next placeholder
        return (
          <strong key={index}>
            {"{{"}
            <input
              type="text"
              name={part}
              value={summaryDataState[part]}
              placeholder={tempObj[currentIndex]}
              onChange={(e) =>
                handleChangeSummary(
                  tempObj[currentIndex],
                  currentIndex,
                  e.target.value,
                )
              }
            />
            {"}}"}
          </strong>
        );
      }
      // Return static text parts
      return part;
    });
  };

  const handleChangeInstrution = (part, index, newValue) => {
    setInstructionDataState((prev) => ({
      ...prev,
      [part]: newValue,
    }));
  };

  const tempObjInstrution = {
    0: "Enter Requestor",
    1: "Enter Client Name",
    2: "Enter Vessel Name",
  };

  const renderInstructionWithEditableFields = (text) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);

    let inputFieldIndex = 0; // Keep track of the input field's index

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const currentIndex = inputFieldIndex; // Capture the current input field index

        inputFieldIndex++; // Increment for the next placeholder
        return (
          <strong key={index}>
            {"{{"}
            <input
              type="text"
              name={part}
              value={instructionDataState[part]}
              placeholder={tempObjInstrution[currentIndex]}
              onChange={(e) =>
                handleChangeInstrution(
                  tempObjInstrution[currentIndex],
                  currentIndex,
                  e.target.value,
                )
              }
            />
            {"}}"}
          </strong>
        );
      }
      // Return static text parts
      return part;
    });
  };

  const handleChangeOrderOfTrial = (part, index, newValue) => {
    setOrderOfTrialState((prev) => ({
      ...prev,
      [part]: newValue,
    }));
  };

  const tempObjOrderOfTrial = {
    0: "Enter trial DP",
    1: "Enter Start Approximately",
    2: "Enter Start Approximately on",
    3: "Enter End Time",
    4: "Enter End Date",
    5: "Enter Location",
    6: "Enter Depth",
    7: "Enter Weather Condition",
    8: "Enter Sea State",
  };

  const renderOrderOfTrialWithEditableFields = (text) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);

    let inputFieldIndex = 0; // Keep track of the input field's index

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const currentIndex = inputFieldIndex; // Capture the current input field index

        inputFieldIndex++; // Increment for the next placeholder
        return (
          <strong key={index}>
            {"{{"}
            <input
              type="text"
              name={part}
              value={orderOfTrialState[part]}
              placeholder={tempObjOrderOfTrial[currentIndex]}
              onChange={(e) =>
                handleChangeOrderOfTrial(
                  tempObjOrderOfTrial[currentIndex],
                  currentIndex,
                  e.target.value,
                )
              }
            />
            {"}}"}
          </strong>
        );
      }
      // Return static text parts
      return part;
    });
  };

  const handleChangeVesselGeneral = (part, index, newValue) => {
    setVesselGeneralState((prev) => ({
      ...prev,
      [part]: newValue,
    }));
  };

  const tempObjVesselGeneral = {
    0: "Enter Vessel Name",
    1: "Enter Year Built",
    2: "Enter Vessel",
    3: "Enter Builder & Location",
    4: "Enter Class Notation",
    5: "Enter Number of Bow Tunnel Thrusters",
    6: "Enter Number of Stern Tunnel Thrusters",
    7: "Enter Type of Main Propeller",
  };
  const renderVesselGeneralWithEditableFields = (text) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);

    let inputFieldIndex = 0; // Keep track of the input field's index

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const currentIndex = inputFieldIndex; // Capture the current input field index

        inputFieldIndex++; // Increment for the next placeholder
        return (
          <strong key={index}>
            {"{{"}
            <input
              type="text"
              name={part}
              value={VesselGeneralState[part]}
              placeholder={tempObjVesselGeneral[currentIndex]}
              onChange={(e) =>
                handleChangeVesselGeneral(
                  tempObjVesselGeneral[currentIndex],
                  currentIndex,
                  e.target.value,
                )
              }
            />
            {"}}"}
          </strong>
        );
      }
      // Return static text parts
      return part;
    });
  };

  const handleVesselGeneralTable = (placeholder, value) => {
    setVesselGeneralTableData((prev) => ({
      ...prev,
      [placeholder]: value,
    }));
  };

  const handleSummaryBlur = () => {
    setSummaryData(divRefSummary.current.innerText);
  };
  const handleDisclaimerData = () => {
    setDisclaimerData(divRefDisclaimer?.current?.innerText);
  };
  const handleIntrutionData = () => {
    setInstructionData(divRefInstruction.current.innerText);
  };
  const handleOrderOfTrial = () => {
    setOrderOfTrial(divRefOrderOfTrial.current.innerText);
  };
  const handleRefAndAssPub = () => {
    setRefAndAssPub(divRefRefAndAsstPub.current.innerText);
  };
  const handleScopeOfWork = () => {
    setScopeOfWork(divRefScopeOfWork.current.innerText);
  };
  const handleReqDuringTest = () => {
    setReqDuringTest(divRefReqDuringTest.current.innerText);
  };
  const handleVesselGeneral = () => {
    setVesselGeneral(divRefVesselGeneral.current.innerText);
  };
  const handleMachineConfDuringTest = () => {
    setMachineConfDuringTest(divRefMachineConfDuringTest.current.innerText);
  };
  const handleWorstCaseFailure = () => {
    setWorstCaseFailure(divRefWorstCaseFailure);
  };

  // Handler for input changes
  const handleInputChangeSwitchboard = (field) => (e) => {
    setSwitchboardMaintenance({
      ...switchboardMaintenance,
      [field]: e.target.value,
    });
  };

  // Handler for input changes in each row
  const handleInputChangeDpSystemMaintain = (index, field) => (e) => {
    const updatedRows = [...dpSystemMaintain];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: e.target.value,
    };
    setDpSystemMaintain(updatedRows);
  };

  // Add a new row to dpSystemMaintain
  const addRow13 = () => {
    setDpSystemMaintain([
      ...dpSystemMaintain,
      { sensor: "", record_checked: "", remarks: "" },
    ]);
  };

  // Remove a row from dpSystemMaintain
  const removeRow13 = (index) => {
    const updatedRows = dpSystemMaintain.filter((_, i) => i !== index);
    setDpSystemMaintain(updatedRows);
  };

  // Handler for general remarks input
  const handleGeneralRemarksChange = (e) => {
    setGeneralRemarks(e.target.value);
  };

  const addColumnMachineryMaintainence = () => {
    const newColumnId = `col${machineryMaintenanceColumns.length + 1}`;
    setMachineryMaintenanceColumns([
      ...machineryMaintenanceColumns,
      { id: newColumnId, label: `New Column`, width: "15%" },
    ]);
    setMachineryMaintenanceRows((rows) => rows.map((row) => [...row, ""]));
  };

  const removeColumnMachineryMaintainence = (index) => {
    if (index === 0 || machineryMaintenanceColumns.length <= 2) return;
    setMachineryMaintenanceColumns((columns) =>
      columns.filter((_, i) => i !== index),
    );
    setMachineryMaintenanceRows((rows) =>
      rows.map((row) => row.filter((_, i) => i !== index)),
    );
  };

  const addRowMachineryMaintainence = () => {
    const newRow = Array(machineryMaintenanceColumns.length).fill("");
    newRow[0] = `New Row ${machineryMaintenanceRows.length + 1}`;
    setMachineryMaintenanceRows([...machineryMaintenanceRows, newRow]);
  };

  const removeRowMachineryMaintainence = (index) => {
    if (index === 0) return;
    setMachineryMaintenanceRows((rows) => rows.filter((_, i) => i !== index));
  };

  const handleCellChange = (rowIndex, colIndex, value) => {
    const updatedRows = [...machineryMaintenanceRows];
    updatedRows[rowIndex][colIndex] = value;
    setMachineryMaintenanceRows(updatedRows);
  };

  const handleColumnLabelChange = (index, value) => {
    const updatedColumns = [...machineryMaintenanceColumns];
    updatedColumns[index].label = value;
    setMachineryMaintenanceColumns(updatedColumns);
  };

  const addColumnMainEngines = () => {
    const newColumnId = `col${mainEnginesColumns.length + 1}`;
    setMainEnginesColumns([
      ...mainEnginesColumns,
      { id: newColumnId, label: `New Column`, width: "15%" },
    ]);
    setMainEnginesRows((rows) => rows.map((row) => [...row, ""]));
  };

  const removeColumnMainEngines = (index) => {
    if (index === 0 || mainEnginesColumns.length <= 2) return;
    setMainEnginesColumns((columns) => columns.filter((_, i) => i !== index));
    setMainEnginesRows((rows) =>
      rows.map((row) => row.filter((_, i) => i !== index)),
    );
  };

  const addRowMainEngines = () => {
    const newRow = Array(mainEnginesColumns.length).fill("");
    newRow[0] = `New Row ${mainEnginesRows.length + 1}`;
    setMainEnginesRows([...mainEnginesRows, newRow]);
  };

  const removeRowMainEngines = (index) => {
    if (index === 0) return;
    setMainEnginesRows((rows) => rows.filter((_, i) => i !== index));
  };

  const handleCellChangeMainEngines = (rowIndex, colIndex, value) => {
    const updatedRows = [...mainEnginesRows];
    updatedRows[rowIndex][colIndex] = value;
    setMainEnginesRows(updatedRows);
  };

  const handleColumnLabelChangeMainEngines = (index, value) => {
    const updatedColumns = [...mainEnginesColumns];
    updatedColumns[index].label = value;
    setMainEnginesColumns(updatedColumns);
  };

  return (
    <>
      {/* <FormHeader
        centralText="DP 2 FMEA Proving Trials"
        rightText="#VSL NAME"
      /> */}
      <div id="Verification Page" className="fmea-container">
        <div className="image-container">
          <img src={img1} alt="Logo 1" />
          <img src={img2} alt="Logo 2" />
        </div>

        <div className="spacer"></div>

        <div className="heading-container">
          {roleType === "Admin" ? (
            <>
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "0px auto",
                    width: "450px",
                    marginBottom: "15px",
                  }}
                >
                  <label htmlFor="" style={{ display: "flex" }}>
                    Survey Name
                  </label>
                  <TextInput
                    type="text"
                    name="survey_name"
                    customholder="Survey Name"
                    onChange={(value) => {
                      setAMCAFrontPage((prev) => ({
                        ...prev,
                        surveyName: value,
                      }));
                    }}
                    value={amcaFrontPage?.surveyName}
                  />
                </div>
              </div>
              <div className="verification-page">
                <div>
                  <label htmlFor="" style={{ display: "flex" }}>
                    Vessel Name
                  </label>
                  <TextInput
                    type="text"
                    name="veseel_name"
                    customholder="Vessel Name"
                    width="32.426%"
                    onChange={(value) => {
                      setAMCAFrontPage((prev) => ({
                        ...prev,
                        vesselName: value,
                      }));
                    }}
                    value={amcaFrontPage?.vesselName}
                  />
                </div>
                <div>
                  <label htmlFor="" style={{ display: "flex" }}>
                    FMEA Trial
                  </label>
                  <TextInput
                    type="text"
                    name="fmeaTrial"
                    customholder="FMEA Trial"
                    width="32.426%"
                    onChange={(value) => {
                      setAMCAFrontPage((prev) => ({
                        ...prev,
                        fmeaTrial: value,
                      }));
                    }}
                    value={amcaFrontPage?.fmeaTrial}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "600px",
                  margin: "0 auto",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      width: "50%",
                      textAlign: "right",
                      paddingRight: "15px",
                      fontSize: "16px",
                    }}
                  >
                    Survey Name:
                  </span>
                  <span
                    style={{
                      width: "50%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {amcaFrontPage.vesselName}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      width: "50%",
                      textAlign: "right",
                      paddingRight: "15px",
                      fontSize: "16px",
                    }}
                  >
                    Vessel Name:
                  </span>
                  <span
                    style={{
                      width: "50%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {amcaFrontPage.vesselName}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      width: "50%",
                      textAlign: "right",
                      paddingRight: "15px",
                      fontSize: "16px",
                    }}
                  >
                    FMEA Trial:
                  </span>
                  <span
                    style={{
                      width: "50%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {amcaFrontPage.fmeaTrial}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="spacer"></div>

        <div className="table-container">
          <table className="info-table">
            <tbody>
              <tr>
                <td>Prepared For</td>
                <td>:</td>
                <td>
                  {roleType === "Admin" ? (
                    <TextInput
                      type="text"
                      name="clientName"
                      customholder="Client Name"
                      width="32.426%"
                      onChange={(value) => {
                        setAMCAFrontPage((prev) => ({
                          ...prev,
                          clientName: value,
                        }));
                      }}
                      value={amcaFrontPage?.clientName}
                    />
                  ) : (
                    amcaFrontPage?.clientName
                  )}
                </td>
              </tr>
              <tr>
                <td>Prepared By</td>
                <td>:</td>
                <td>
                  {roleType === "Admin" ? (
                    <TextInput
                      type="text"
                      name="preparedBy"
                      customholder="Prepared By"
                      width="32.426%"
                      onChange={(value) => {
                        setAMCAFrontPage((prev) => ({
                          ...prev,
                          preparedBy: value,
                        }));
                      }}
                      value={amcaFrontPage?.preparedBy}
                    />
                  ) : (
                    amcaFrontPage?.preparedBy
                  )}
                </td>
              </tr>
              <tr>
                <td>Doc. No.</td>
                <td>:</td>
                <td>
                  {roleType === "Admin" ? (
                    <TextInput
                      type="text"
                      name="docNo"
                      customholder="Document Number"
                      width="32.426%"
                      onChange={(value) => {
                        setAMCAFrontPage((prev) => ({
                          ...prev,
                          docNo: value,
                        }));
                      }}
                      value={amcaFrontPage?.docNo}
                    />
                  ) : (
                    amcaFrontPage?.docNo
                  )}
                </td>
              </tr>
              <tr>
                <td>Rev. No.</td>
                <td>:</td>
                <td>
                  {roleType === "Admin" ? (
                    <TextInput
                      type="text"
                      name="revNo"
                      customholder="Revision Number"
                      width="32.426%"
                      onChange={(value) => {
                        setAMCAFrontPage((prev) => ({
                          ...prev,
                          revNo: value,
                        }));
                      }}
                      value={amcaFrontPage?.revNo}
                    />
                  ) : (
                    amcaFrontPage?.revNo
                  )}
                </td>
              </tr>
              <tr>
                <td>Date</td>
                <td>:</td>
                <td>
                  {roleType === "Admin" ? (
                    <>
                      {/* <input
                        type="text"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      /> */}
                      <div style={{ position: "relative", width: "77.426%" }}>
                        <DateSelector
                          placeholder="Select Date"
                          onChange={async (option) => {
                            setAMCAFrontPage((prev) => ({
                              ...prev,
                              date: option,
                            }));
                          }}
                          selectedDate={amcaFrontPage?.date}
                        />
                      </div>
                    </>
                  ) : (
                    amcaFrontPage?.date
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="footer">
            Corr. Add: Office No. -704, Business Bay, Plot No.-51, Sector-1,
            Nerul, Navi Mumbai; E-mail: info@amcamarine.com
          </div>

          {/* {edit && <button onClick={handleSave}>Save</button>} */}
        </div>
      </div>
      <div className="disclaimer-container">
        <div id="Disclaimer" className="disclaimer-heading">
          DISCLAIMER
        </div>
        <div
          className="disclaimer-text-summary"
          contentEditable={roleType === "Admin" && true}
          ref={divRefDisclaimer}
          onBlur={() => {
            handleDisclaimerData();
          }}
        >
          {disclaimerData}
        </div>
      </div>
      <div className="disclaimer-container">
        <div id="Summary" className="disclaimer-heading">
          SUMMARY
        </div>
        <div
          className="disclaimer-text-summary"
          contentEditable={roleType === "Admin" && true}
          // onInput={handleInput}
          ref={divRefSummary}
          onBlur={handleSummaryBlur}
        >
          {renderSummaryWithEditableFields(summaryData)}
        </div>
      </div>
      <div className="disclaimer-container">
        {/* Document Revision Record Table */}
        <div
          id="Document Revision Record"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Document Revision Record
        </div>
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "100%",
            msOverflowY: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th style={thStyle}>Rev</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>Prep.</th>
                <th style={thStyle}>Review</th>
                <th style={thStyle}>Appr</th>
              </tr>
            </thead>
            <tbody>
              {docRevRecords?.map((record, index) => (
                <tr key={index}>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.rev}</span>
                    ) : (
                      <TextInput
                        type="text"
                        width="32.426%"
                        onChange={(value) => {
                          handleChange("docRev", index, "rev", value);
                        }}
                        value={record.rev || ""}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.date}</span>
                    ) : (
                      <div style={{ position: "relative", width: "200px" }}>
                        <DateSelector
                          placeholder="Date Select"
                          onChange={(option) => {
                            handleChange("docRev", index, "date", option);
                          }}
                          disabled={roleType === "Admin"}
                        />
                      </div>
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.description}</span>
                    ) : (
                      <TextInput
                        type="text"
                        width="32.426%"
                        onChange={(value) => {
                          handleChange("docRev", index, "description", value);
                        }}
                        value={record.description}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.prep}</span>
                    ) : (
                      <TextInput
                        type="text"
                        width="32.426%"
                        onChange={(value) => {
                          handleChange("docRev", index, "prep", value);
                        }}
                        value={record.prep}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.review}</span>
                    ) : (
                      <TextInput
                        type="text"
                        width="32.426%"
                        onChange={(value) => {
                          handleChange("docRev", index, "review", value);
                        }}
                        value={record.review}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.appr}</span>
                    ) : (
                      <TextInput
                        type="text"
                        width="32.426%"
                        onChange={(value) => {
                          handleChange("docRev", index, "appr", value);
                        }}
                        value={record.appr}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  {!previewMode && roleType === "Admin" && (
                    <td style={{ ...removeTd, display: "flex" }}>
                      <div
                        style={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleRemoveRow("docRev", index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {!previewMode && roleType === "Admin" && (
                <tr>
                  <td colSpan={7} style={removeTd}>
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={() => handleAddRow("docRev")}
                    >
                      Add Row
                    </CustomButton>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Amendment Record Table */}
        <div
          id="Amendment Record"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Amendment Record
        </div>
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "100%",
            OverflowY: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th style={thStyle}>Revision</th>
                <th style={thStyle}>Description of Changes</th>
              </tr>
            </thead>
            <tbody>
              {amendmentRecords?.map((record, index) => (
                <tr key={index}>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.revision}</span>
                    ) : (
                      <TextInput
                        type="text"
                        onChange={(value) => {
                          handleChange("amendment", index, "revision", value);
                        }}
                        value={record.revision || ""}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  <td className="td">
                    {previewMode ? (
                      <span>{record.description}</span>
                    ) : (
                      <TextInput
                        type="text"
                        onChange={(value) => {
                          handleChange(
                            "amendment",
                            index,
                            "description",
                            value,
                          );
                        }}
                        value={record.description || ""}
                        disabled={roleType === "Admin"}
                      />
                    )}
                  </td>
                  {!previewMode && roleType === "Admin" && (
                    <td className="td" style={removeTd}>
                      {/* <button
                        onClick={() => handleRemoveRow("amendment", index)}
                      >
                        Remove
                      </button> */}
                      <div
                        style={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleRemoveRow("amendment", index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {!previewMode && roleType === "Admin" && (
                <tr>
                  <td colSpan={3} style={removeTd}>
                    {/* <button onClick={() => handleAddRow("amendment")}>
                      Add Row
                    </button> */}
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={() => handleAddRow("amendment")}
                    >
                      Add Row
                    </CustomButton>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="overview-container">
        <div id="Overview" className="overview-heading">
          Section A: Overview
        </div>
        <div id="A-1: Introduction" className="overview-heading">
          A-1: Introduction
        </div>
        <div
          id="A-1.1: Instruction"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.1.: Instruction
        </div>
        <div
          className="disclaimer-text-summary"
          ref={divRefInstruction}
          onInput={handleIntrutionData}
          contentEditable={roleType === "Admin" ? true : false}
        >
          {renderInstructionWithEditableFields(instructionData)}
        </div>
        <div
          id="A-1.2: Scope of Work"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.2: Scope of Work
        </div>
        <div
          className="overview-text"
          contentEditable={roleType === "Admin" ? true : false}
          style={
            roleType === "Admin" ? { padding: "10px" } : { padding: "0px" }
          }
          ref={divRefScopeOfWork}
          // onInput={handleScopeOfWork}
          onBlur={handleScopeOfWork}
        >
          {scopeOfWork}
        </div>
        <div
          id="A-1.3: Referenced and Associated Publications"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.3: Referenced and Associated Publications
        </div>
        <div
          className="reference-text"
          contentEditable={roleType === "Admin" ? true : false}
          ref={divRefRefAndAsstPub}
          // onInput={handleRefAndAssPub}
          onBlur={handleRefAndAssPub}
        >
          {refAndAssPub}
        </div>
        <div
          id="A-1.4: Key Personnel in Attendance at the Trials"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.4: Key Personnel in Attendance at the Trials
        </div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            {crewData.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    padding: "12px",
                    border: "1px solid black",
                  }}
                >
                  {roleType === "Admin" ? (
                    <input
                      type="text"
                      value={row.position}
                      onChange={(e) =>
                        handlePositionChange(index, e.target.value)
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter position"
                    />
                  ) : (
                    row.position
                  )}
                </td>
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "50%",
                  }}
                >
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.name}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                      placeholder=""
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.name || ""
                  )}
                </td>
                {roleType === "Admin" && (
                  // <td style={{ padding: "10px", border: "1px solid black" }}>
                  // <button onClick={() => deleteRow(index)}>Delete Row</button>
                  <div
                    style={{
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                    onClick={() => deleteRow(index)}
                  >
                    <Delete />
                  </div>
                  // </td>
                )}
              </tr>
            ))}
          </tbody>
          {roleType === "Admin" && (
            <tfoot>
              <tr>
                <td colSpan="3" style={{ ...removeTd }}>
                  <div style={{ marginTop: "15px", marginBottom: "25px" }}>
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={addRow}
                    >
                      Add Row
                    </CustomButton>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        <div
          id="A-1.5: Order of Trials"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.5: Order of Trials
        </div>
        <div
          className="overview-text"
          contentEditable={roleType === "Admin" ? true : false}
          style={
            roleType === "Admin" ? { padding: "10px" } : { padding: "0px" }
          }
          ref={divRefOrderOfTrial}
          // onInput={handleOrderOfTrial}
          onBlur={handleOrderOfTrial}
        >
          {renderOrderOfTrialWithEditableFields(orderOfTrial)}
        </div>
        <div
          id="A-1.6: Requirements during Testing"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.6: Requirements during Testing
        </div>
        <div
          contentEditable={roleType === "Admin"}
          style={
            roleType === "Admin" ? { padding: "10px" } : { padding: "0px" }
          }
          ref={divRefReqDuringTest}
          // onInput={handleReqDuringTest}
          onBlur={handleReqDuringTest}
        >
          {reqDuringTest}
        </div>
        <div
          id="A-2: Vessel Particulars"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2: Vessel Particulars
        </div>
        <div
          id="A-2.1: General"
          className="overview-heading"
          style={{ marginTop: "5px" }}
        >
          A-2.1: General
        </div>
        <div
          className="disclaimer-text"
          contentEditable={roleType === "Admin"}
          ref={divRefVesselGeneral}
          // onInput={handleVesselGeneral}
          onBlur={handleVesselGeneral}
        >
          {renderVesselGeneralWithEditableFields(VesselGeneral)}
        </div>
        <table style={tableStyle}>
          <tbody>
            <tr>
              <td style={thStyle}>Type</td>
              <td style={cellStyle} colSpan="3">
                {renderInputOrText(
                  "Vessel Type",
                  vesselGeneralTableData?.type,
                  roleType,
                  (value) => handleVesselGeneralTable("type", value),
                )}
              </td>
              <td style={thStyle}>Class</td>
              <td>
                {renderInputOrText(
                  "Class",
                  vesselGeneralTableData?.class,
                  roleType,
                  (value) => handleVesselGeneralTable("class", value),
                )}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Length</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Length",
                  vesselGeneralTableData?.length,
                  roleType,
                  (value) => handleVesselGeneralTable("length", value),
                )}
              </td>
              <td style={thStyle}>Speed</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Speed",
                  vesselGeneralTableData?.speed,
                  roleType,
                  (value) => handleVesselGeneralTable("speed", value),
                )}
              </td>
              <td style={thStyle}>Builder</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Builder",
                  vesselGeneralTableData?.builder,
                  roleType,
                  (value) => handleVesselGeneralTable("builder", value),
                )}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Breadth</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Breath",
                  vesselGeneralTableData?.breath,
                  roleType,
                  (value) => handleVesselGeneralTable("breath", value),
                )}
              </td>
              <td style={thStyle}>Flag</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Flag",
                  vesselGeneralTableData?.flag,
                  roleType,
                  (value) => handleVesselGeneralTable("flag", value),
                )}
              </td>
              <td style={thStyle}>Built</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Built",
                  vesselGeneralTableData?.built,
                  roleType,
                  (value) => handleVesselGeneralTable("built", value),
                )}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Draft(S)</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Draft",
                  vesselGeneralTableData?.draft,
                  roleType,
                  (value) => handleVesselGeneralTable("draft", value),
                )}
              </td>
              <td style={thStyle}>IMO No.</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "IMO No.",
                  vesselGeneralTableData?.imo_no,
                  roleType,
                  (value) => handleVesselGeneralTable("imo_no", value),
                )}
              </td>
              <td style={thStyle}>Owner</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Owner",
                  vesselGeneralTableData?.owner,
                  roleType,
                  (value) => handleVesselGeneralTable("owner", value),
                )}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Dead Weight</td>
              <td style={cellStyle}>
                {renderInputOrText(
                  "Dead Weight",
                  vesselGeneralTableData?.dead_weight,
                  roleType,
                  (value) => handleVesselGeneralTable("dead_weight", value),
                )}
              </td>
              <td style={thStyle}></td>
              <td style={cellStyle}></td>
              <td style={thStyle}></td>
              <td style={cellStyle}></td>
            </tr>
          </tbody>
        </table>
        <div
          id="A-2.2: Main Engines & Auxiliary Diesel Engine Generators"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.2: Main Engines & Auxiliary Diesel Engine Generators
        </div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                #
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Description
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Rated Power
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Rated Speed
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Model
              </th>
            </tr>
          </thead>
          <tbody>
            {engineAndAuxiliary.map((row, index) => (
              <tr key={index}>
                {/* # and Description editable for backoffice */}
                <td style={{ padding: "10px", border: "1px solid black" }}>
                  {roleType === "Admin" ? (
                    <input
                      type="text"
                      value={row.me_number}
                      onChange={(e) =>
                        handleInputChange(index, "me_number", e.target.value)
                      }
                      placeholder="Enter ID"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.me_number
                  )}
                </td>
                <td style={{ padding: "10px", border: "1px solid black" }}>
                  {roleType === "Admin" ? (
                    <input
                      type="text"
                      value={row.description}
                      onChange={(e) =>
                        handleInputChange(index, "description", e.target.value)
                      }
                      placeholder="Enter Description"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.description
                  )}
                </td>

                {/* Editable Rated Power, Rated Speed, and Model for surveyor */}
                <td style={{ padding: "10px", border: "1px solid black" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.rated_power}
                      onChange={(e) =>
                        handleInputChange(index, "rated_power", e.target.value)
                      }
                      placeholder="Enter Rated Power"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.rated_power
                  )}
                </td>
                <td style={{ padding: "10px", border: "1px solid black" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.rated_speed}
                      onChange={(e) =>
                        handleInputChange(index, "rated_speed", e.target.value)
                      }
                      placeholder="Enter Rated Speed"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.rated_speed
                  )}
                </td>
                <td style={{ padding: "10px", border: "1px solid black" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.model}
                      onChange={(e) =>
                        handleInputChange(index, "model", e.target.value)
                      }
                      placeholder="Enter Model"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.model
                  )}
                </td>

                {/* Delete Row button for backoffice */}
                {roleType === "Admin" && (
                  <td style={{ ...removeTd, padding: "10px" }}>
                    {/* <button onClick={() => removeRow1(index)}>Delete Row</button> */}
                    <div
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => removeRow1(index)}
                    >
                      <Delete />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Add Row button for backoffice */}
        {roleType === "Admin" && (
          // <button
          //   onClick={addRow1}
          //   style={{ marginTop: "10px", width: "max-content" }}
          // >
          //   Add Row
          // </button>
          <div style={{ marginTop: "15px", marginBottom: "25px" }}>
            <CustomButton
              type="btn-primary"
              iconRequired={true}
              handleClick={addRow1}
              style={{ width: "max-content" }}
            >
              Add Row
            </CustomButton>
          </div>
        )}
        <div
          id="A-2.3: Thrusters and Propulsion"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.3: Thrusters and Propulsion
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  #
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Type & Location
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Pitch
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Propeller Speed
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Model
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Drive
                </th>
                {/* {roleType === "Admin" && (
                  <th style={{ border: "1px solid black", padding: "10px" }}>
                    Actions
                  </th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {thrusterPropulsion.map((row, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.id}
                        onChange={(e) =>
                          handleInputChange2(index, "id", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.id
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.type_and_location}
                        onChange={(e) =>
                          handleInputChange2(
                            index,
                            "type_and_location",
                            e.target.value,
                          )
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.type_and_location
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.pitch}
                        onChange={(e) =>
                          handleInputChange2(index, "pitch", e.target.value)
                        }
                        placeholder="Enter Pitch"
                        style={{ width: "100%" }}
                      />
                    ) : row.pitch !== "" ? (
                      row.pitch
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.propeller_speed}
                        onChange={(e) =>
                          handleInputChange2(
                            index,
                            "propeller_speed",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Propeller Speed"
                        style={{ width: "100%" }}
                      />
                    ) : row.propellerSpeed !== "" ? (
                      row.propeller_speed
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.model}
                        onChange={(e) =>
                          handleInputChange2(index, "model", e.target.value)
                        }
                        placeholder="Enter Model"
                        style={{ width: "100%" }}
                      />
                    ) : row.model !== "" ? (
                      row.model
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.drive}
                        onChange={(e) =>
                          handleInputChange2(index, "drive", e.target.value)
                        }
                        placeholder="Enter Drive"
                        style={{ width: "100%" }}
                      />
                    ) : row.drive !== "" ? (
                      row.drive
                    ) : (
                      ""
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td
                      style={{
                        ...removeTd,
                        padding: "10px",
                      }}
                    >
                      {/* <button onClick={() => removeRow2(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow2(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {roleType === "Admin" && (
          // <button
          //   onClick={addRow2}
          //   style={{ marginTop: "10px", width: "max-content" }}
          // >
          //   Add Row
          // </button>
          <div style={{ marginTop: "15px", marginBottom: "25px" }}>
            <CustomButton
              type="btn-primary"
              iconRequired={true}
              handleClick={addRow2}
              style={{ width: "max-content" }}
            >
              Add Row
            </CustomButton>
          </div>
        )}
        <div
          id="A-2.4: Electric Distribution"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.4: Electric Distribution
        </div>
        <div>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Gen
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Switch-board
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Generator
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Engine
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Control
                </th>
                {/* {roleType === "Admin" && (
                  <th style={{ border: "1px solid black", padding: "10px" }}>
                    Actions
                  </th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {electricDistribution.map((row, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.id}
                        onChange={(e) =>
                          handleInputChange3(index, "id", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.id
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.switch_board}
                        onChange={(e) =>
                          handleInputChange3(
                            index,
                            "switch_board",
                            e.target.value,
                          )
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.switch_board
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.generator}
                        onChange={(e) =>
                          handleInputChange3(index, "generator", e.target.value)
                        }
                        placeholder="Enter Generator"
                        style={{ width: "100%" }}
                      />
                    ) : row.generator !== "" ? (
                      row.generator
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.engine}
                        onChange={(e) =>
                          handleInputChange3(index, "engine", e.target.value)
                        }
                        placeholder="Enter Engine"
                        style={{ width: "100%" }}
                      />
                    ) : row.engine !== "" ? (
                      row.engine
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.control}
                        onChange={(e) =>
                          handleInputChange3(index, "control", e.target.value)
                        }
                        placeholder="Enter Control"
                        style={{ width: "100%" }}
                      />
                    ) : row.control !== "" ? (
                      row.control
                    ) : (
                      ""
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, padding: "10px" }}>
                      {/* <button onClick={() => removeRow3(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow3(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {roleType === "Admin" && (
            // <button onClick={addRow3} style={{ marginTop: "10px" }}>
            //   Add Row
            // </button>
            <div style={{ marginTop: "15px", marginBottom: "25px" }}>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={addRow3}
                style={{ width: "max-content" }}
              >
                Add Row
              </CustomButton>
            </div>
          )}
        </div>
        <div
          id="A-2.5: VESSEL LAYOUT / REDUNDANCY CONCEPT"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.5: VESSEL LAYOUT / REDUNDANCY CONCEPT
        </div>
        <div
          id="A-2.6: DP System"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.6: DP System
        </div>
        <div>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              {/* Equipment Section */}
              <tr>
                <td style={thStyle}>Equipment</td>
                <td style={thStyle}>Make / Model</td>
                {/* {roleType === "Admin" && <td style={thStyle}>Actions</td>} */}
              </tr>
              {equipmentRows.map((row, index) => (
                <tr key={`equipment-${index}`}>
                  <td style={tdStyle}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.equipment}
                        onChange={(e) =>
                          handleInputChange4(
                            "equipment",
                            index,
                            "equipment",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Equipment"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.equipment // Static display for surveyor
                    )}
                  </td>
                  <td style={tdStyle}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.make_model}
                        onChange={(e) =>
                          handleInputChange4(
                            "equipment",
                            index,
                            "make_model",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Make / Model"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.make_model // Static display for backoffice
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={removeTd}>
                      {/* <button onClick={() => removeRow4("equipment", index)}>
                        Remove
                      </button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow4("equipment", index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {roleType === "Admin" && (
                <tr style={{ border: "none" }}>
                  <td colSpan="3" style={{ border: "none" }}>
                    {/* <button onClick={() => addRow4("equipment")}>
                      Add Equipment Row
                    </button> */}
                    <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={() => addRow4("equipment")}
                        style={{ width: "max-content" }}
                      >
                        Add Equipment Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}

              {/* Position Reference System (PRS) Section */}
              <tr>
                <td style={thStyle}>Position Reference System (PRS)</td>
                <td style={thStyle}>Make / Model</td>
                {/* {roleType === "Admin" && <td style={thStyle}>Actions</td>} */}
              </tr>
              {prsRows.map((row, index) => (
                <tr key={`prs-${index}`}>
                  <td style={tdStyle}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.equipment}
                        onChange={(e) =>
                          handleInputChange4(
                            "prs",
                            index,
                            "equipment",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Equipment"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.equipment
                    )}
                  </td>
                  <td style={tdStyle}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.make_model}
                        onChange={(e) =>
                          handleInputChange4(
                            "prs",
                            index,
                            "make_model",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Make / Model"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.make_model
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={removeTd}>
                      {/* <button onClick={() => removeRow4("prs", index)}>
                        Remove
                      </button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow4("prs", index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {roleType === "Admin" && (
                <tr style={{ border: "none" }}>
                  <td colSpan="3" style={{ border: "none" }}>
                    {/* <button onClick={() => addRow4("prs")}>Add PRS Row</button> */}
                    <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={() => addRow4("prs")}
                        style={{ width: "max-content" }}
                      >
                        Add PRS Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}

              {/* Sensors Section */}
              <tr>
                <td style={thStyle}>Sensors</td>
                <td style={thStyle}>Make / Model</td>
                {/* {roleType === "Admin" && <td style={thStyle}>Actions</td>} */}
              </tr>
              {sensorRows.map((row, index) => (
                <tr key={`sensors-${index}`}>
                  <td style={tdStyle}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.equipment}
                        onChange={(e) =>
                          handleInputChange4(
                            "sensors",
                            index,
                            "equipment",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Equipment"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.equipment
                    )}
                  </td>
                  <td style={tdStyle}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.make_model}
                        onChange={(e) =>
                          handleInputChange4(
                            "sensors",
                            index,
                            "make_model",
                            e.target.value,
                          )
                        }
                        placeholder="Enter Make / Model"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.make_model
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={removeTd}>
                      {/* <button onClick={() => removeRow4("sensors", index)}>
                        Remove
                      </button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow4("sensors", index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {roleType === "Admin" && (
                <tr style={{ border: "none" }}>
                  <td colSpan="3" style={{ border: "none" }}>
                    {/* <button onClick={() => addRow4("sensors")}>
                      Add Sensors Row
                    </button> */}
                    <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={() => addRow4("sensors")}
                        style={{ width: "max-content" }}
                      >
                        Add Sensors Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="A-2.7: MACHINERY CONFIGURATION DURING TRIALS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.7: MACHINERY CONFIGURATION DURING TRIALS
        </div>
        <div
          contentEditable={roleType === "Admin"}
          style={
            roleType === "Admin" ? { padding: "10px" } : { padding: "0px" }
          }
          ref={divRefMachineConfDuringTest}
          // onInput={handleMachineConfDuringTest}
          onBlur={handleMachineConfDuringTest}
        >
          {machineConfDuringTest}
        </div>
        <div
          id="A-2.8: WORST CASE FAILURE DESIGN INTENT"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.8: WORST CASE FAILURE DESIGN INTENT
        </div>
        <div
          contentEditable={roleType === "Admin" && true}
          ref={divRefWorstCaseFailure}
          onInput={handleWorstCaseFailure}
        >
          {worstCaseFailure}
        </div>
        <div
          id="A-3: Trials Summary"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-3: Trials Summary
        </div>
        <h4 style={{ fontSize: "18px" }}>
          *The Trial Summary shall be updated after the Trials
        </h4>
        <div>
          <div
            id="A-3.1: Documentation"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.1: Documentation
          </div>
          {renderEditorOrSpace("doc")}

          <div
            id="A-3.2: Machinery Maintenance"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.2: Machinery Maintenance
          </div>
          {renderEditorOrSpace("maintenance")}

          <div
            id="A-3.3: DP System Maintenance"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.3: DP System Maintenance
          </div>
          {renderEditorOrSpace("dpSystem")}

          <div
            id="A-3.4: Incidents"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.4: Incidents
          </div>
          {renderEditorOrSpace("incidents")}

          <div
            id="A-3.5: Power Generation"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.5: Power Generation
          </div>
          {renderEditorOrSpace("powerGen")}

          <div
            id="A-3.6: Power Distribution"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.6: Power Distribution
          </div>
          {renderEditorOrSpace("powerDist")}

          <div
            id="A-3.7: Power Management"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.7: Power Management
          </div>
          {renderEditorOrSpace("powerMgmt")}

          <div
            id="A-3.8: Control Loops"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.8: Control Loops
          </div>
          {renderEditorOrSpace("controlLoops")}

          <div
            id="A-3.9: Environmental and Heading Sensors"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.9: Environmental and Heading Sensors
          </div>
          {renderEditorOrSpace("envSensors")}

          <div
            id="A-3.10: Position References"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.10: Position References
          </div>
          {renderEditorOrSpace("posRefs")}

          <div
            id="A-3.11: DP Control"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.11: DP Control
          </div>
          {renderEditorOrSpace("dpControl")}

          <div
            id="A-3.12: Personnel"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.12: Personnel
          </div>
          {renderEditorOrSpace("personnel")}

          <div
            id="A-3.13: Trials Conclusions"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.13: Trials Conclusions
          </div>
          {renderEditorOrSpace("trialsConclusions")}
        </div>
        <div id="Repeat Findings (Still Open) from Previous DP Trials">
          At the time of the DP trials covered by this report the following
          findings were considered to still be open following review of previous
          DP trials reports and FMEA documents, as well as the associated close
          out documentation:
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "70%" }}>
                  Finding Category and Description
                </th>
                <th style={{ ...thStyle, width: "30%" }}>Finding Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ ...headerStyle, width: "70%" }}>Category A</td>
                <td style={{ ...headerStyle, width: "30%" }}></td>
              </tr>
              <tr>
                <td style={{ ...cellStyle, width: "70%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter details"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "None"
                  )}
                </td>
                <td style={{ ...cellStyle, width: "30%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="date"
                      placeholder="Select date"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "NA"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ ...headerStyle, width: "70%" }}>Category B</td>
                <td style={{ ...headerStyle, width: "30%" }}></td>
              </tr>
              <tr>
                <td style={{ ...cellStyle, width: "70%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter details"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "None"
                  )}
                </td>
                <td style={{ ...cellStyle, width: "30%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="date"
                      placeholder="Select date"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "NA"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ ...headerStyle, width: "70%" }}>Category C</td>
                <td style={{ ...headerStyle, width: "30%" }}></td>
              </tr>
              <tr>
                <td style={{ ...cellStyle, width: "70%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter details"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "None"
                  )}
                </td>
                <td style={{ ...cellStyle, width: "30%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="date"
                      placeholder="Select date"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    "NA"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          Any open findings have been included in the findings contained under
          Section A-5: Findings.
        </div>
        <div
          id="A-5: Findings"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-5: Findings
        </div>
        <div>
          <p>Findings are grouped under three headings:</p>
          <ul>
            <li>A – For immediate attention;</li>
            <li>B – For action when reasonably convenient;</li>
            <li>C – For future attention/consideration.</li>
          </ul>

          <p>
            Findings from previous DP FMEA and annual DP trials which have not
            been closed out at the conclusion of these trials are to be included
            with the original date of the finding.
          </p>
        </div>
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.1: A - Findings
        </div>
        <div>
          {roleType === "Surveyor" ? (
            // Render React Quill for "Surveyor" userType
            <div style={{ height: "100%", overflow: "hidden" }}>
              <ReactQuill
                value={editorValueA}
                onChange={handleChangeRTEA}
                style={{ height: "100%", overflow: "hidden" }} // Ensure the editor fits the container
              />
            </div>
          ) : (
            // Render a div with fixed height for other userTypes
            <div style={{ height: "200px", border: "1px solid #ddd" }}>
              {/* Content for non-surveyor users */}
              This is a fixed height div for non-surveyor users.
            </div>
          )}
        </div>
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.2: B - Findings
        </div>
        <div>
          {roleType === "Surveyor" ? (
            // Render React Quill for "surveyor" userType
            <div style={{ height: "100%", overflow: "hidden" }}>
              <ReactQuill
                value={editorValueB}
                onChange={handleChangeRTEB}
                style={{ height: "100%", overflow: "hidden" }} // Ensure the editor fits the container
              />
            </div>
          ) : (
            // Render a div with fixed height for other userTypes
            <div style={{ height: "200px", border: "1px solid #ddd" }}>
              {/* Content for non-surveyor users */}
              This is a fixed height div for non-surveyor users.
            </div>
          )}
        </div>{" "}
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.3: C - Findings
        </div>
        <div>
          {roleType === "Surveyor" ? (
            // Render React Quill for "surveyor" userType
            <div style={{ height: "100%", overflow: "hidden" }}>
              <ReactQuill
                value={editorValueC}
                onChange={handleChangeRTEC}
                style={{ height: "100%", overflow: "hidden" }} // Ensure the editor fits the container
              />
            </div>
          ) : (
            // Render a div with fixed height for other userTypes
            <div style={{ height: "200px", border: "1px solid #ddd" }}>
              {/* Content for non-surveyor users */}
              This is a fixed height div for non-surveyor users.
            </div>
          )}
        </div>{" "}
        <div>
          The evidence for Closing-Out of these Findings can be found in Section
          C at the end of this document.
        </div>
        <div
          id="Section B: Trials Records"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Section B: Trials Records
        </div>
        <div
          id="B-1: Document and Maintenance Audit"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1: Document and Maintenance Audit
        </div>
        <div
          id="B-1.1: Machinery maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.1: Machinery maintenance
        </div>
        <div style={{ overflowX: "scroll" }}>
          {/* <table style={tableStyle}>
            <thead>
              <tr>
                {machineryMaintenanceColumns.map((col, index) => (
                  <th key={col.id} style={{ ...headerStyle, width: col.width }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={col.label}
                        onChange={(e) =>
                          handleColumnLabelChange(index, e.target.value)
                        }
                        style={{ width: "100%", fontWeight: "bold" }}
                      />
                    ) : (
                      col.label
                    )}
                    {roleType === "Admin" && index > 0 && (
                      <button
                        onClick={() => removeColumnMachineryMaintainence(index)}
                        style={{ marginLeft: "10px" }}
                      >
                        Remove Column
                      </button>
                    )}
                  </th>
                ))}
                {roleType === "Admin" && (
                  <th style={{ textAlign: "center" }}>
                    <button onClick={addColumnMachineryMaintainence}>
                      Add Column
                    </button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {machineryMaintenanceRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      style={colIndex === 0 ? thStyle : cellStyle}
                    >
                      <input
                        type="text"
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(rowIndex, colIndex, e.target.value)
                        }
                        style={{
                          width: "100%",
                          ...(colIndex === 0 ? { fontWeight: "bold" } : {}),
                        }}
                        readOnly={roleType !== "Admin" && colIndex === 0}
                      />
                    </td>
                  ))}
                  {roleType === "Admin" && (
                    <td>
                      <button
                        onClick={() => removeRowMachineryMaintainence(rowIndex)}
                      >
                        Remove Row
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td
                  colSpan={machineryMaintenanceColumns.length}
                  style={cellStyle}
                >
                  <input
                    type="text"
                    value={machineryMaintainenceRemarks}
                    onChange={(e) =>
                      setMachineryMaintainenceRemarks(e.target.value)
                    }
                    style={{ width: "100%" }}
                    readOnly={roleType !== "Admin" && roleType !== "surveyor"}
                  />
                </td>
              </tr>
            </tbody>
          </table> */}
          <table style={tableStyle}>
            <thead>
              <tr>
                {machineryMaintenanceColumns.map((col, index) => (
                  <th
                    key={col.id}
                    style={{
                      ...headerStyle,
                      width: col.width,
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {/* Input for column headers (first row) only for Admin */}
                    {roleType === "Admin" ? (
                      <div style={{ display: "flex" }}>
                        <input
                          type="text"
                          value={col.label}
                          onChange={(e) =>
                            handleColumnLabelChange(index, e.target.value)
                          }
                          // style={{ width: "100%", fontWeight: "bold" }}
                          style={{
                            fontWeight: "bold",
                            width: index === 0 ? "200px" : "100px",
                          }}
                        />
                        {/* Allow Admin to remove columns */}
                        {roleType === "Admin" && index > 0 && (
                          // <button
                          //   onClick={() => removeColumnMachineryMaintainence(index)}
                          //   style={{ marginLeft: "10px" }}
                          // >
                          //   Remove Column
                          // </button>
                          <div
                            style={{
                              alignItems: "center",
                              // cursor: "pointer",
                              // padding: "5px",
                              cursor: "pointer",
                              padding: "5px",
                              // display: "flex",
                              // justifyContent: "center",
                            }}
                            onClick={() =>
                              removeColumnMachineryMaintainence(index)
                            }
                          >
                            <Delete />
                          </div>
                        )}
                      </div>
                    ) : (
                      col.label
                    )}
                  </th>
                ))}
                {/* Add column button for Admin */}
                {/* {roleType === "Admin" && (
                  <th style={{ textAlign: "center" }}>
                    <button onClick={addColumnMachineryMaintainence}>
                      Add Column
                    </button>
                  </th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {machineryMaintenanceRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      // style={colIndex === 0 ? thStyle : cellStyle}
                      style={{
                        ...(colIndex === 0 ? thStyle : cellStyle),
                        ...(colIndex === 0 && roleType === "Surveyor"
                          ? { backgroundColor: "#f2f2f2" }
                          : { backgroundColor: "white" }),
                      }}
                    >
                      {/* Input for row headers (first column) only for Admin */}
                      {roleType === "Admin" && colIndex === 0 ? (
                        <input
                          type="text"
                          value={cell}
                          onChange={(e) =>
                            handleCellChange(rowIndex, colIndex, e.target.value)
                          }
                          style={{ width: "100%", fontWeight: "bold" }}
                        />
                      ) : roleType === "Surveyor" && colIndex !== 0 ? (
                        /* Input for Surveyor in non-header cells */
                        <input
                          type="text"
                          value={cell}
                          onChange={(e) =>
                            handleCellChange(rowIndex, colIndex, e.target.value)
                          }
                          style={{ width: "100%" }}
                        />
                      ) : (
                        /* Display cell value for non-editable cells */
                        <span>{cell}</span>
                      )}
                    </td>
                  ))}
                  {/* Allow Admin to remove rows */}
                  {roleType === "Admin" && (
                    <td style={removeTd}>
                      {/* <button
                        onClick={() => removeRowMachineryMaintainence(rowIndex)}
                      >
                        Remove Row
                      </button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRowMachineryMaintainence(rowIndex)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {/* Remarks row */}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td
                  colSpan={machineryMaintenanceColumns.length - 1}
                  style={cellStyle}
                >
                  {roleType === "Admin" ? (
                    <>
                      <span>{machineryMaintainenceRemarks}</span>
                    </>
                  ) : (
                    <input
                      type="text"
                      value={machineryMaintainenceRemarks}
                      onChange={(e) =>
                        setMachineryMaintainenceRemarks(e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          {roleType === "Admin" && (
            // <button
            //   onClick={addRowMachineryMaintainence}
            //   style={{ marginTop: "10px" }}
            // >
            //   Add Row
            // </button>
            <div style={{ display: "flex", gap: "20px", marginBottom: "25px" }}>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={addRowMachineryMaintainence}
                style={{ width: "max-content" }}
              >
                Add Row
              </CustomButton>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={addColumnMachineryMaintainence}
                style={{ width: "max-content" }}
              >
                Add Column
              </CustomButton>
            </div>
          )}
        </div>
        <div style={{ overflowX: "scroll" }}>
          {/* <table style={tableStyle}>
            <thead>
              <tr>
                {mainEnginesColumns.map((col, index) => (
                  <th key={col.id} style={{ ...headerStyle, width: col.width }}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={col.label}
                        onChange={(e) =>
                          handleColumnLabelChangeMainEngines(
                            index,
                            e.target.value,
                          )
                        }
                        style={{ width: "100%", fontWeight: "bold" }}
                      />
                    ) : (
                      col.label
                    )}
                    {roleType === "Admin" && index > 0 && (
                      <button
                        onClick={() => removeColumnMainEngines(index)}
                        style={{ marginLeft: "10px" }}
                      >
                        Remove Column
                      </button>
                    )}
                  </th>
                ))}
                {roleType === "Admin" && (
                  <th style={{ textAlign: "center" }}>
                    <button onClick={addColumnMainEngines}>Add Column</button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {mainEnginesRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      style={colIndex === 0 ? thStyle : cellStyle}
                    >
                      <input
                        type="text"
                        value={cell}
                        onChange={(e) =>
                          handleCellChangeMainEngines(
                            rowIndex,
                            colIndex,
                            e.target.value,
                          )
                        }
                        style={{
                          width: "100%",
                          ...(colIndex === 0 ? { fontWeight: "bold" } : {}),
                        }}
                        readOnly={roleType !== "Admin" && colIndex === 0}
                      />
                    </td>
                  ))}
                  {roleType === "Admin" && (
                    <td>
                      <button onClick={() => removeRowMainEngines(rowIndex)}>
                        Remove Row
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td colSpan={mainEnginesColumns.length} style={cellStyle}>
                  <input
                    type="text"
                    value={mainEnginesRemarks}
                    onChange={(e) => setMainEnginesRemarks(e.target.value)}
                    style={{ width: "100%" }}
                    // readOnly={roleType !== "Admin" && roleType !== "surveyor"}
                  />
                </td>
              </tr>
            </tbody>
          </table> */}
          <table style={tableStyle}>
            <thead>
              <tr>
                {mainEnginesColumns.map((col, index) => (
                  <th
                    key={col.id}
                    style={{
                      ...headerStyle,
                      width: col.width,
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {/* Input for column headers (first row) only for Admin */}
                    {roleType === "Admin" ? (
                      <div style={{ display: "flex" }}>
                        <input
                          type="text"
                          value={col.label}
                          onChange={(e) =>
                            handleColumnLabelChangeMainEngines(
                              index,
                              e.target.value,
                            )
                          }
                          // style={{ fontWeight: "bold", width: "100%" }}
                          style={{
                            fontWeight: "bold",
                            width: index === 0 ? "200px" : "100px",
                          }}
                        />
                        {/* Allow Admin to remove columns */}
                        {roleType === "Admin" && index > 0 && (
                          // <button
                          //   onClick={() => removeColumnMainEngines(index)}
                          //   style={{ marginLeft: "10px" }}
                          // >
                          //   Remove Column
                          // </button>
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                // justifyContent: "center",
                                // margin: "0px auto",
                                cursor: "pointer",
                                padding: "5px",
                                // display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => removeColumnMainEngines(index)}
                            >
                              <Delete />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      col.label
                    )}
                  </th>
                ))}
                {/* Add column button for Admin */}
                {/* {roleType === "Admin" && (
                  <th style={{ textAlign: "center" }}>
                    <button onClick={addColumnMainEngines}>Add Column</button>
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={addColumnMainEngines}
                      style={{ width: "max-content" }}
                    >
                      Add Column
                    </CustomButton>
                  </th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {mainEnginesRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      // style={colIndex === 0 ? thStyle : cellStyle}
                      style={{
                        ...(colIndex === 0 ? thStyle : cellStyle),
                        ...(colIndex === 0 && roleType === "Surveyor"
                          ? { backgroundColor: "#f2f2f2" }
                          : { backgroundColor: "white" }),
                      }}
                    >
                      {/* Input for row headers (first column) only for Admin */}
                      {roleType === "Admin" && colIndex === 0 ? (
                        <input
                          type="text"
                          value={cell}
                          onChange={(e) =>
                            handleCellChangeMainEngines(
                              rowIndex,
                              colIndex,
                              e.target.value,
                            )
                          }
                          style={{ width: "100%", fontWeight: "bold" }}
                        />
                      ) : roleType === "Surveyor" && colIndex !== 0 ? (
                        /* Input for Surveyor in non-header cells */
                        <input
                          type="text"
                          value={cell}
                          onChange={(e) =>
                            handleCellChangeMainEngines(
                              rowIndex,
                              colIndex,
                              e.target.value,
                            )
                          }
                          style={{ width: "100%" }}
                        />
                      ) : (
                        /* Display cell value for non-editable cells */
                        <span>{cell}</span>
                      )}
                    </td>
                  ))}
                  {/* Allow Admin to remove rows */}
                  {roleType === "Admin" && (
                    <td style={removeTd}>
                      {/* <button onClick={() => removeRowMainEngines(rowIndex)}>
                        Remove Row
                      </button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRowMainEngines(rowIndex)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {/* Remarks row */}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td colSpan={mainEnginesColumns.length - 1} style={cellStyle}>
                  {roleType === "Admin" ? (
                    <>
                      <span>{mainEnginesRemarks}</span>
                    </>
                  ) : (
                    <input
                      type="text"
                      value={mainEnginesRemarks}
                      onChange={(e) => setMainEnginesRemarks(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          {roleType === "Admin" && (
            // <button onClick={addRowMainEngines} style={{ marginTop: "10px" }}>
            //   Add Row
            // </button>
            <div style={{ display: "flex", gap: "20px", marginBottom: "25px" }}>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={addRowMainEngines}
                style={{ width: "max-content" }}
              >
                Add Row
              </CustomButton>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={addColumnMainEngines}
                style={{ width: "max-content" }}
              >
                Add Column
              </CustomButton>
            </div>
          )}
        </div>
        <div
          id="B-1.2: Switchboard Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.2: Switchboard Maintenance
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "25%" }}>Report Number</th>
                <th style={{ ...thStyle, width: "25%" }}>Date</th>
                <th style={{ ...thStyle, width: "50%" }}>Service Company</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>Work Completed</td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {roleType === "Surveyor" ? (
                    // <input
                    //   type="text"
                    //   placeholder="Enter date"
                    //   value={switchboardMaintenance.workCompletedDate}
                    //   onChange={handleInputChangeSwitchboard(
                    //     "workCompletedDate",
                    //   )}
                    //   style={{ width: "100%" }}
                    // />
                    <div style={{ position: "relative", width: "200px" }}>
                      <DateSelector
                        placeholder="Select Date"
                        onChange={async (option) => {
                          setSwitchboardMaintenance((prev) => ({
                            ...prev,
                            workCompletedDate: option,
                          }));
                        }}
                        selectedDate={switchboardMaintenance.workCompletedDate}
                      />
                    </div>
                  ) : null}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter service company"
                      value={switchboardMaintenance.workCompletedServiceCompany}
                      onChange={handleInputChangeSwitchboard(
                        "workCompletedServiceCompany",
                      )}
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
              </tr>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>
                  Modifications since last Trials
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {roleType === "Surveyor" ? (
                    <div style={{ position: "relative", width: "200px" }}>
                      <DateSelector
                        placeholder="Select Date"
                        onChange={async (option) => {
                          setSwitchboardMaintenance((prev) => ({
                            ...prev,
                            modificationsDate: option,
                          }));
                        }}
                        selectedDate={switchboardMaintenance.modificationsDate}
                      />
                    </div>
                  ) : null}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter service company"
                      value={switchboardMaintenance.modificationsServiceCompany}
                      onChange={handleInputChangeSwitchboard(
                        "modificationsServiceCompany",
                      )}
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
              </tr>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>Comments</td>
                <td colSpan={2} style={{ ...cellStyle, width: "75%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter comments"
                      value={switchboardMaintenance.comments}
                      onChange={(e) =>
                        setSwitchboardMaintenance((prev) => ({
                          ...prev,
                          comments: e.target.value,
                        }))
                      }
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.3: DP System Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.3: DP System Maintenance
        </div>
        <div>
          Dates should be the last recorded at the Planned Maintenance System or
          DP Maintenance Logbook.
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "100%" }} colSpan="3">
                  DP SENSORS & REFERENCE SYSTEMS
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "33.33%" }}>SENSOR</th>
                <th style={{ ...thStyle, width: "33.33%" }}>RECORD CHECKED</th>
                <th style={{ ...thStyle, width: "33.33%" }}>REMARKS</th>
                {/* Empty header for remove button column */}
              </tr>
            </thead>
            <tbody>
              {/* Static Rows */}
              {dpSystemMaintain.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...cellStyle,
                      width: "33.33%",
                      // backgroundColor: "#f2f2f2",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.sensor}
                        onChange={handleInputChangeDpSystemMaintain(
                          index,
                          "sensor",
                        )}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.sensor
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "33.33%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.record_checked}
                        placeholder="Enter record checked"
                        onChange={handleInputChangeDpSystemMaintain(
                          index,
                          "record_checked",
                        )}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.record_checked
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "33.33%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.remarks}
                        placeholder="Enter remarks"
                        onChange={handleInputChangeDpSystemMaintain(
                          index,
                          "remarks",
                        )}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.remarks
                    )}
                  </td>
                  <td style={removeTd}>
                    {roleType === "Admin" && (
                      // <button
                      //   onClick={() => removeRow13(index)}
                      //   style={{ marginTop: "5px", display: "block" }}
                      // >
                      //   Remove
                      // </button>
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow13(index)}
                      >
                        <Delete />
                      </div>
                    )}
                  </td>
                </tr>
              ))}

              <tr>
                <td
                  style={{
                    ...cellStyle,
                    width: "33.33%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Remarks :
                </td>
                <td style={cellStyle} colSpan="2">
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter overall remarks"
                      style={{ width: "100%" }}
                      value={generalRemarks}
                      onChange={handleGeneralRemarksChange} // Update state on change
                    />
                  ) : null}
                </td>
              </tr>
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="4" style={removeTd}>
                    {/* <button onClick={addRow13} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow13}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div id="DP Computers" style={{ overflowX: "scroll" }}>
          <table style={{ ...tableStyle, marginTop: "20px" }}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "100%" }} colSpan="2">
                  DP COMPUTERS
                </th>
              </tr>
            </thead>
            <tbody>
              {dpComputers.slice(0, -1).map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...cellStyle,
                      width: "50%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={row.label}
                        onChange={(e) => {
                          // Handle input change for backoffice
                          // Assuming `row.label` is the editable field
                          const newRows = [...dpComputers];
                          newRows[index].label = e.target.value;
                          setDpComputers(newRows);
                        }}
                        placeholder="Enter text"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.label
                    )}
                  </td>

                  <td style={{ ...cellStyle, width: "50%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={row.value}
                        onChange={(e) => {
                          const newRows = [...dpComputers];
                          newRows[index].value = e.target.value;
                          setDpComputers(newRows);
                        }}
                        placeholder={`Enter ${row.label.toLowerCase()}`}
                        style={{ width: "100%" }}
                      />
                    ) : null}
                  </td>
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "10%" }}>
                      {/* <button onClick={() => removeRow12(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow12(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}

              {/* "Remarks" row */}
              <tr>
                <td
                  style={{
                    ...cellStyle,
                    width: "50%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {/* {dpComputers[dpComputers.length - 1].label} */}
                  Remarks
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={dpComputers[dpComputers.length - 1].value}
                      onChange={(e) => {
                        const newRows = [...dpComputers];
                        newRows[dpComputers.length - 1].value = e.target.value;
                        setDpComputers(newRows);
                      }}
                      placeholder={`Enter ${dpComputers[
                        dpComputers.length - 1
                      ].label.toLowerCase()}`}
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
              </tr>
              {/* Button to add a new row */}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="2" style={removeTd}>
                    {/* <button onClick={addRow12} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow12}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.4: Battery Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.4: Battery Maintenance
        </div>
        <div>
          Dates should be the last recorded at the Planned Maintenance System or
          Battery Maintenance Logbook.
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              >
                Unit
              </th>
              <th style={{ ...thStyle, width: "50%" }} colSpan="2">
                Battery Maintenance Dates
              </th>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              >
                Remarks
              </th>
            </tr>
            <tr>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              ></th>
              <th style={{ ...thStyle, width: "25%" }}>Installed</th>
              <th style={{ ...thStyle, width: "25%" }}>Last discharge test</th>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {batteryMaintenance.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    ...cellStyle,
                    width: "25%",
                    // backgroundColor: "#f2f2f2",
                    backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                  }}
                >
                  {roleType === "Admin" ? (
                    <input
                      type="text"
                      value={row.unit}
                      onChange={(e) => {
                        const newRows = [...batteryMaintenance];
                        newRows[index].unit = e.target.value;
                        setbatteryMaintenance(newRows);
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.unit
                  )}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.installed}
                      onChange={(e) => {
                        const newRows = [...batteryMaintenance];
                        newRows[index].installed = e.target.value;
                        setbatteryMaintenance(newRows);
                      }}
                      placeholder="Enter installation date"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.last_discharge_test}
                      onChange={(e) => {
                        const newRows = [...batteryMaintenance];
                        newRows[index].last_discharge_test = e.target.value;
                        setbatteryMaintenance(newRows);
                      }}
                      placeholder="Enter last discharge test date"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={row.remarks}
                      onChange={(e) => {
                        const newRows = [...batteryMaintenance];
                        newRows[index].remarks = e.target.value;
                        setbatteryMaintenance(newRows);
                      }}
                      placeholder="Enter remarks"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
                {roleType === "Admin" && (
                  <td style={{ ...removeTd, width: "10%" }}>
                    {/* <button onClick={() => removeRow11(index)}>Remove</button> */}
                    <div
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => removeRow11(index)}
                    >
                      <Delete />
                    </div>
                  </td>
                )}
              </tr>
            ))}
            {roleType === "Admin" && (
              <tr>
                <td colSpan="5" style={removeTd}>
                  {/* <button onClick={addRow11} style={{ marginBottom: "10px" }}>
                    Add Row
                  </button> */}
                  <div style={{ marginTop: "15px" }}>
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={addRow11}
                      style={{ width: "max-content" }}
                    >
                      Add Row
                    </CustomButton>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div
          id="B-1.5: Checklists and DP Related Documentation"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.5: Checklists and DP Related Documentation
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {checkListDP.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={item.label}
                        onChange={(e) =>
                          handleLabelCheckListDPChange(index, e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.label
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        value={item.value}
                        onChange={(e) =>
                          handleValueCheckListDPChange(index, e.target.value)
                        }
                        placeholder="Enter details"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.value
                    )}
                  </td>
                  {/* Remove Row button */}
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "10%" }}>
                      {/* <button onClick={() => removeRow10(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow10(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}

              {/* Remarks row */}
              <tr>
                <td style={{ ...thStyle, width: "50%" }}>Remarks :</td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      value={checkListDPRemarks}
                      onChange={(e) => setCheckListDPRemarks(e.target.value)}
                      placeholder="Enter remarks"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    checkListDPRemarks
                  )}
                </td>
              </tr>
              {/* Add Row button for Admin users */}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="2" style={removeTd}>
                    {/* <button onClick={addRow10} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow10}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.6: HARDWARE MODIFICATIONS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.6: HARDWARE MODIFICATIONS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {hardwareModification.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={item.label}
                        onChange={(e) => handleHardwareLabelChange(e, index)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.label
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter details"
                        value={item.value}
                        onChange={(e) => handleHardwareValueChange(e, index)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.value
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "10%" }}>
                      {/* <button onClick={() => removeRow9(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow9(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="2" style={removeTd}>
                    {/* <button onClick={addRow9} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow9}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.7: CAPABILITY PLOTS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.7: CAPABILITY PLOTS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {capabilityPlots.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={item.label}
                        onChange={(e) => handleCapabilityLabelChange(e, index)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.label
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter details"
                        value={item.value}
                        onChange={(e) => handleCapabilityValueChange(e, index)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.value
                    )}
                  </td>
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "10%" }}>
                      {/* <button onClick={() => removeRow8(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow8(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="3" style={removeTd}>
                    {/* <button onClick={addRow8} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow8}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.8: DP INCIDENTS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.8: DP INCIDENTS
        </div>
        <table style={tableStyle}>
          <tbody>
            {dpIncident.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    ...thStyle,
                    width: "50%",
                    backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                  }}
                >
                  {roleType === "Admin" ? (
                    <input
                      type="text"
                      placeholder="Enter question"
                      value={row.question}
                      onChange={(e) => {
                        const newRows = [...dpIncident];
                        newRows[index].question = e.target.value;
                        setDPIncident(newRows);
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    row.question
                  )}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter details"
                      value={row.details}
                      onChange={(e) => {
                        const newRows = [...dpIncident];
                        newRows[index].details = e.target.value;
                        setDPIncident(newRows);
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </td>
                {/* Remove Row button */}
                {roleType === "Admin" && (
                  <td style={{ ...removeTd, width: "10%" }}>
                    {/* <button onClick={() => removeRow7(index)}>Remove</button> */}
                    <div
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => removeRow7(index)}
                    >
                      <Delete />
                    </div>
                  </td>
                )}
              </tr>
            ))}

            {/* Remarks row */}
            <tr>
              <td style={{ ...thStyle, width: "50%" }}>Remarks:</td>
              <td style={{ ...cellStyle, width: "50%" }}>
                {roleType === "Surveyor" ? (
                  // <input
                  //   type="text"
                  //   placeholder="Enter remarks"
                  //   style={{ width: "100%" }}
                  // />
                  <input
                    type="text"
                    placeholder="Enter remarks"
                    value={dpIncidentRemarks}
                    onChange={handleDPIncidentRemarksChange}
                    style={{ width: "100%" }}
                  />
                ) : null}
              </td>
            </tr>
            {/* Add Row button for 'backoffice' users */}
            {roleType === "Admin" && (
              <tr>
                <td colSpan="2" style={removeTd}>
                  {/* <button onClick={addRow7} style={{ marginBottom: "10px" }}>
                    Add Row
                  </button> */}
                  <div style={{ marginTop: "15px" }}>
                    <CustomButton
                      type="btn-primary"
                      iconRequired={true}
                      handleClick={addRow7}
                      style={{ width: "max-content" }}
                    >
                      Add Row
                    </CustomButton>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div
          id="B-1.9: DP Training of Personnel"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.9: DP Training of Personnel
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "20%" }}>Position</th>
                <th style={{ ...thStyle, width: "20%" }}>Certificate Type</th>
                <th style={{ ...thStyle, width: "20%" }}>Certificate Number</th>
                <th style={{ ...thStyle, width: "20%" }}>Date Issued</th>
                <th style={{ ...thStyle, width: "20%" }}>Issued by</th>
              </tr>
            </thead>
            <tbody>
              {dpTraningPersonal.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "20%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        placeholder="Enter Position"
                        value={row.position}
                        onChange={(e) => {
                          const newRows = [...dpTraningPersonal];
                          newRows[index].position = e.target.value;
                          setDPTrainingPersonal(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.position
                    )}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter Certificate Type"
                        value={row.certificate_type}
                        onChange={(e) => {
                          const newRows = [...dpTraningPersonal];
                          newRows[index].certificate_type = e.target.value;
                          setDPTrainingPersonal(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : null}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter Certificate Number"
                        value={row.certificate_number}
                        onChange={(e) => {
                          const newRows = [...dpTraningPersonal];
                          newRows[index].certificate_number = e.target.value;
                          setDPTrainingPersonal(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : null}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {roleType === "Surveyor" ? (
                      <div style={{ position: "relative", width: "200px" }}>
                        <DateSelector
                          placeholder="Select Date"
                          onChange={(option) => {
                            const newRows = [...dpTraningPersonal];
                            newRows[index].date_issued = option;
                            setDPTrainingPersonal(newRows);
                          }}
                          selectedDate={dpTraningPersonal.date_issued}
                        />
                      </div>
                    ) : null}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter Issued By"
                        value={row.issued_by}
                        onChange={(e) => {
                          const newRows = [...dpTraningPersonal];
                          newRows[index].issued_by = e.target.value;
                          setDPTrainingPersonal(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : null}
                  </td>
                  {/* Remove Row button */}
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "10%" }}>
                      {/* <button onClick={() => removeRow6(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow6(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}

              {/* Remarks row */}
              <tr>
                <td style={{ ...thStyle, width: "20%" }}>Remarks</td>
                <td colSpan="4" style={{ ...cellStyle }}>
                  {roleType === "Surveyor" ? (
                    // <input
                    //   type="text"
                    //   placeholder="Remarks"
                    //   style={{ width: "100%" }}
                    // />
                    <input
                      type="text"
                      placeholder="Enter remarks"
                      value={dpTraningPersonalRemarks}
                      onChange={(e) =>
                        setDpTraningPersonalRemarks(e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div style={{ height: "100px" }}></div>
                  )}
                </td>
              </tr>
              {/* Add Row button for 'backoffice' users */}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="5" style={removeTd}>
                    {/* <button onClick={addRow6} style={{ marginBottom: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow6}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.10: PRE-TRIAL CHECKS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.10: PRE-TRIAL CHECKS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {preTrialCheck.map((row, index) => (
                <tr key={index}>
                  {/* Left column: Label or Input for Admin */}
                  <td
                    style={{
                      ...thStyle,
                      width: "48%",
                      backgroundColor: roleType === "Surveyor" ? "#f2f2f2" : "",
                    }}
                  >
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        placeholder="Enter left column text"
                        value={row.label}
                        onChange={(e) => {
                          const newRows = [...preTrialCheck];
                          newRows[index].label = e.target.value;
                          setPreTrialCheck(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      row.label
                    )}
                  </td>

                  {/* Right column: Surveyor's input */}
                  <td style={{ ...cellStyle, width: "49%" }}>
                    {roleType === "Surveyor" ? (
                      <input
                        type="text"
                        placeholder="Enter details"
                        value={row.value}
                        onChange={(e) => {
                          const newRows = [...preTrialCheck];
                          newRows[index].value = e.target.value;
                          setPreTrialCheck(newRows);
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : null}
                  </td>

                  {/* Remove Row button */}
                  {roleType === "Admin" && (
                    <td style={{ ...removeTd, width: "2%" }}>
                      {/* <button onClick={() => removeRow5(index)}>Remove</button> */}
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => removeRow5(index)}
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {/* Remarks row */}
              <tr>
                <td style={{ ...thStyle, fontWeight: "bold" }}>Remarks</td>
                <td colSpan="1" style={{ ...cellStyle }}>
                  {roleType === "Surveyor" ? (
                    <input
                      type="text"
                      placeholder="Enter remarks"
                      value={preTrialCheckremarks}
                      onChange={(e) => setPreTrialCheckRemarks(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div style={{ height: "100px" }}></div>
                  )}
                </td>
              </tr>
              {/* Add Row button for 'Admin' */}
              {roleType === "Admin" && (
                <tr>
                  <td colSpan="3" style={removeTd}>
                    {/* <button onClick={addRow5} style={{ marginRight: "10px" }}>
                      Add Row
                    </button> */}
                    <div style={{ marginTop: "15px" }}>
                      <CustomButton
                        type="btn-primary"
                        iconRequired={true}
                        handleClick={addRow5}
                        style={{ width: "max-content" }}
                      >
                        Add Row
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          id="B-2: DP TRIAL TEST SCHEDULE"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-2: DP TRIAL TEST SCHEDULE
        </div>
        <div>
          {roleType === "Surveyor" ? (
            // Render React Quill for "Surveyor" userType
            <div style={{ height: "100%", overflow: "hidden" }}>
              <ReactQuill
                value={editorValueDpTrialTestSchedule}
                onChange={handleChangeRTEDpTrialTestSchedule}
                style={{ height: "100%", overflow: "hidden" }} // Ensure the editor fits the container
              />
            </div>
          ) : (
            // Render a div with fixed height for other userTypes
            <div style={{ height: "200px", border: "1px solid #ddd" }}>
              {/* Content for non-surveyor users */}
              This is a fixed height div for non-surveyor users.
            </div>
          )}
        </div>
        <div style={{ height: "50px" }}></div>
        <FMEATest
          roleType={roleType}
          testDetails={testDetails}
          setTestDetails={setTestDetails}
          tableData={tableData}
          setTableData={setTableData}
        />
        <div
          id="B-2: DP TRIAL TEST SCHEDULE"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          APPENDIX C – OBSERVATIONS CLOSE OUT
        </div>
        <div>
          {roleType === "Surveyor" ? (
            // Render React Quill for "Surveyor" userType
            <div style={{ height: "100%", overflow: "hidden" }}>
              <ReactQuill
                value={editorValueObservationsCloseOut}
                onChange={handleChangeObservationsCloseOut}
                style={{ height: "100%", overflow: "hidden" }} // Ensure the editor fits the container
                className="custom-quill-editor"
              />
            </div>
          ) : (
            // Render a div with fixed height for other userTypes
            <div style={{ height: "200px", border: "1px solid #ddd" }}>
              {/* Content for non-surveyor users */}
              This is a fixed height div for non-surveyor users.
            </div>
          )}
        </div>
      </div>
      {/* <FormFooter
        docNo="AMCA-FMEA-TRIAL/XXXX/XX. Rev. 0	"
        rightText="Page 8 of 124"
      /> */}
    </>
  );
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
};
const tdStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
};

const thStyle = {
  ...tdStyle,
  backgroundColor: "#f2f2f2",
  fontWeight: "bold",
};
const headerStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid black",
  textAlign: "left",
  fontWeight: "bold",
};

const cellStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
  width: "16.66%", // Added this line to make all cells equal width
};

const crewHeaderStyle = {
  ...headerStyle,
  width: "50%",
};

const crewCellStyle = {
  ...cellStyle,
  width: "50%",
};

const removeTd = {
  border: "none",
};

export default Overview;
