import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";

// Layouts
import AuthLayout from "../component/layouts/AuthLayout";
import DashboardLayout from "../component/layouts/DashboardLayout";
import SurveyLayout from "../component/layouts/surveyLayout";

// import None from "../components/pages/none";

// Auth
import Login from "../component/pages/auth/login";
import Verification from "../component/pages/auth/verification";
import ResetOrCreatePass from "../component/pages/auth/resetOrCreatePass";
import ForgotPassword from "../component/pages/auth/forgotPassword";

// Dashboard
// import Home from "../component/pages/dashboard/home";
import Settings from "../component/pages/dashboard/settings";
import Survey from "../component/pages/dashboard/Survey";
import None from "../component/pages/dashboard/None";
import AddSurvey from "../component/pages/dashboard/Survey/AddSurvey/addSurvey";
import Edit from "../component/pages/dashboard/edit-profile/index";
import ChangePassword from "../component/pages/dashboard/edit-profile/change-password/index";
import ShareGuard from "./shareGuard";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetOrCreatePass type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetOrCreatePass type="create" />,
      },
    ],
  },
  {
    path: "dashboard/*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: "dashboard/survey/add/:id?*",
    element: (
      <AuthGuard>
        <SurveyLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
  {
    path: "dashboard/survey/edit/:id?*",
    element: (
      <AuthGuard>
        <SurveyLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
  {
    path: "dashboard/survey/surveyor/:id?*",
    element: (
      <ShareGuard>
        <SurveyLayout />
      </ShareGuard>
    ),
    children: [
      {
        index: true,
        element: <None />,
      },
    ],
  },
];

export const surveyRoute = (
  <Suspense>
    <Routes>
      <Route
        path="survey/add"
        element={
          <AuthGuard>
            <AddSurvey />
          </AuthGuard>
        }
      />
      <Route
        path="survey/edit/:id"
        element={
          <AuthGuard>
            <AddSurvey type="edit" />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export const dashboardRoute = (
  <Suspense>
    <Routes>
      {/* <Route
        // path="/"
        index
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      /> */}
      {/* <Route
        path="home"
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      /> */}
      <Route
        path="/edit-profile"
        element={
          <AuthGuard>
            <Edit />
          </AuthGuard>
        }
      />

      <Route
        path="/edit-profile/change-password"
        element={
          <AuthGuard>
            <ChangePassword />
          </AuthGuard>
        }
      />

      <Route
        path="survey"
        element={
          <AuthGuard>
            <Survey />
          </AuthGuard>
        }
      />
      <Route
        path="survey/edit/:id"
        element={
          <AuthGuard>
            <AddSurvey type="edit" />
          </AuthGuard>
        }
      />

      <Route
        path="setting"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />

      <Route
        path="none"
        element={
          <AuthGuard>
            <None />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export default routes;
